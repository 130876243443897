
<template>
  <div>
    <div class="row page-title mb-5 mt-5 pb-5 pt-5">
      <div class="col-12 offset-1">
        <h1 class="">Maintenance!</h1>
      </div>
    </div>
    <div class="page-content row">
      <div class="col-12 col-lg-8 offset-lg-4">
        <p>
          <b>Heroes</b>, the site is under maintenance.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ThankYou"
};
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped>
</style>
