<template>
  <div id="search-user">
    <div>
      <div class="page-title row mb-5 mt-5">
        <div class="col-10 offset-1">
          <h2 class="font-weight-bold">Search user</h2>
          <!-- <p class="strong">Don't forget to fill all the fields!</p> -->
        </div>
      </div>

      <form class="" @submit.prevent="searchUser">
        <!-- Personal Info -->
        <div class="form-group row">
          <aside class="col-12 col-lg-4 col-xl-3 offset-xl-1">
            <h2>Search for</h2>
          </aside>

          <div class="col-12 col-lg-8 offset-lg-4 col-xl-7 offset-xl-0">
            <div class="row mb-3">
              <label class="col-12 col-md-3">User ID</label>
              <input
                class="col-12 col-md-9 form-control"
                type="text"
                name="uID"
                v-model="user.uID"
                :disabled="user.name !== '' || user.surname !== ''"
              />
            </div>
            <div class="row mb-3">
              <label class="col-12 col-md-3">Name</label>
              <input
                class="col-12 col-md-9 form-control"
                type="text"
                name="name"
                v-model="user.name"
                :disabled="user.uID !== '' || user.surname !== ''"
              />
            </div>
            <div class="row mb-3">
              <label class="col-12 col-md-3">Surname</label>
              <input
                class="col-12 col-md-9 form-control"
                type="text"
                name="surname"
                v-model="user.surname"
                :disabled="user.uID !== '' || user.name !== ''"
              />
            </div>
          </div>
        </div>

        <!-- Submit + Status -->
        <div class="form-group row">
          <aside class="col-12 col-lg-4 col-xl-3 offset-xl-1"></aside>

          <!-- Submit -->
          <div class="col-12 col-lg-8 offset-lg-4 col-xl-7 offset-xl-0">
            <div class="mb-5">
              <input
                type="submit"
                class="btn"
                :class="submitStatus == 1 ? 'btn-primary' : 'btn-secondary'"
                :value="submitStatus == 1 ? 'Search' : 'Searching'"
                :disabled="submitStatus == 1 ? false : true"
              />
            </div>
            <!-- Status -->
            <div
              class="mb-5 bg-secondary text-white p-3 border mb-3 rounded"
              v-html="status"
              v-if="status"
            ></div>
          </div>
        </div>

        <!-- Result -->
        <div class="form-group row">
          <aside class="col-12 col-lg-4 col-xl-3 offset-xl-1"></aside>

          <!-- Submit -->
          <div class="col-12 col-lg-8 offset-lg-4 col-xl-7 offset-xl-0">
            <div class="row mb-3" v-if="search_result.length > 0">
              <p>Result</p>
            </div>
            <div class="row mb-5">
              <!-- Anagraphical -->
              <div
                v-for="result in search_result"
                v-bind:key="result.uID"
                class="col-12 border p-3 mb-4"
              >
                <div class="">
                  <p class="d-inline-block font-weight-bold pr-2 mb-1">
                    User role:
                  </p>
                  <p class="d-inline-block mb-1">{{ result.role }}</p>
                </div>
                <div class="">
                  <p class="d-inline-block font-weight-bold pr-2 mb-1">
                    User ID:
                  </p>
                  <p class="d-inline-block mb-1">{{ result.uID }}</p>
                </div>
                <div class="">
                  <p class="d-inline-block font-weight-bold pr-2 mb-1">Name:</p>
                  <p class="d-inline-block mb-1">{{ result.name }}</p>
                </div>
                <div class="">
                  <p class="d-inline-block font-weight-bold pr-2 mb-1">
                    Surname:
                  </p>
                  <p class="d-inline-block mb-1">{{ result.surname }}</p>
                </div>
                <div class="">
                  <p class="d-inline-block font-weight-bold pr-2 mb-1">
                    Date of Birth:
                  </p>
                  <p class="d-inline-block mb-1">{{ result.dateOfBirth }}</p>
                </div>
                <div class="">
                  <p class="d-inline-block font-weight-bold pr-2 mb-1">
                    Gender:
                  </p>
                  <p class="d-inline-block mb-1">{{ result.gender }}</p>
                </div>
                <div class="">
                  <p class="d-inline-block font-weight-bold pr-2 mb-1">
                    Phone:
                  </p>
                  <p class="d-inline-block mb-1">{{ result.phone }}</p>
                </div>
                <div class="">
                  <p class="d-inline-block font-weight-bold pr-2 mb-1">
                    Nationality:
                  </p>
                  <p class="d-inline-block mb-1">{{ result.nationality }}</p>
                </div>
                <div class="">
                  <p class="d-inline-block font-weight-bold pr-2 mb-1">
                    Working position:
                  </p>
                  <p class="d-inline-block mb-1">
                    {{ result.workingPosition }}
                  </p>
                </div>
                <div class="">
                  <p class="d-inline-block font-weight-bold pr-2 mb-1">
                    Address:
                  </p>
                  <p class="d-inline-block mb-1">{{ result.address }}</p>
                </div>
                <div class="">
                  <p class="d-inline-block font-weight-bold pr-2 mb-1">
                    ZIP Code:
                  </p>
                  <p class="d-inline-block mb-1">{{ result.zipCode }}</p>
                </div>
                <div class="">
                  <p class="d-inline-block font-weight-bold pr-2 mb-1">City:</p>
                  <p class="d-inline-block mb-1">{{ result.city }}</p>
                </div>
                <div class="">
                  <p class="d-inline-block font-weight-bold pr-2 mb-1">
                    Country:
                  </p>
                  <p class="d-inline-block mb-1">{{ result.country }}</p>
                </div>
                <div class="" v-if="result.groupName || result.groupMembers">
                  <div class="">
                    <p class="d-inline-block font-weight-bold pr-2 mb-1">
                      Group name:
                    </p>
                    <p class="d-inline-block mb-1">{{ result.groupName }}</p>
                  </div>
                  <div class="">
                    <p class="d-inline-block font-weight-bold pr-2 mb-1">
                      Group members:
                    </p>
                    <p class="d-inline-block mb-1">{{ result.groupMembers }}</p>
                  </div>
                </div>



                <!-- Posters -->
                <div class="my-4" v-if="result.posters_result.length == 0">
                  <p class="d-inline-block font-weight-bold pr-2 mb-1">
                    Posters: No posters submitted
                  </p>
                </div>
                <div class="my-4" v-else>
                  <p class="d-inline-block font-weight-bold pr-2 mb-4">
                    Posters:
                  </p>
                  <div
                    class="mb-5"
                    v-for="submission in result.posters_result"
                    v-bind:key="submission.id"
                  >
                    <div class="">
                      <div class="">
                        <ul class="px-3" v-if="submission.posters != null">
                          <li
                            class="mb-4"
                            v-for="poster in submission.posters"
                            :key="poster.id"
                          >
                            <!-- <a v-bind:href="poster.url" target="_blank"> -->
                            <p class="mb-1">
                              <span class="font-weight-bold">Title: </span>
                              <span>{{ poster.title }}</span>
                            </p>
                            <p class="mb-1">
                              <span class="font-weight-bold">Content: </span>
                              <span>{{ poster.content }}</span>
                            </p>
                            <p class="mb-1">
                              <span class="font-weight-bold">Name: </span>
                              <span>{{ poster.name }}</span>
                            </p>
                            <p class="mb-1">
                              <span class="font-weight-bold">Path: </span>
                              <span>{{ poster.path }}</span>
                            </p>
                            <p class="mb-1">
                              <span class="font-weight-bold">Type: </span>
                              <span>{{ poster.type }}</span>
                            </p>
                            <p class="mb-1">
                              <span class="font-weight-bold">Size: </span>
                              <span>{{ poster.size }}</span>
                            </p>
                            <!-- <p class="mb-1">
                              <span class="font-weight-bold">Link: </span>
                              <span>{{ poster.link }}</span>
                            </p> -->
                            <div class="" v-if="poster.link_small !== null">
                              <p class="mb-1">
                                <span class="font-weight-bold">Preview: </span>
                                <a
                                  v-if="poster.type === 'application/pdf'"
                                  class="d-block"
                                  style="width: 50%; cursor: auto"
                                >
                                  <pdf
                                    :src="poster.link"
                                    style="width: 100%"
                                  ></pdf>
                                </a>
                                <a
                                  v-else
                                  class="d-block"
                                  style="width: 50%; cursor: auto"
                                >
                                  <img :src="poster.link_small" style="width: 100%" />
                                </a>
                              </p>
                            </div>
                            <!-- </a> -->
                          </li>
                        </ul>
                        <ul v-else>
                          <li>
                            <em>No posters submitted</em>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <!-- <p class="d-inline-block font-weight-bold pr-2 mb-4">
                    Posters AI:
                  </p>
                  <div
                    class="mb-5"
                    v-for="submission in result.posters_result"
                    v-bind:key="submission.id"
                  >
                    <div class="">
                      <div class="">
                        <ul class="px-3" v-if="submission.posters_ai != null">
                          <li
                            class="mb-4"
                            v-for="poster_ai in submission.posters_ai"
                            :key="poster_ai.id"
                          >
                            <p class="mb-1">
                              <span class="font-weight-bold">Title: </span>
                              <span>{{ poster_ai.title }}</span>
                            </p>
                            <p class="mb-1">
                              <span class="font-weight-bold">Content: </span>
                              <span>{{ poster_ai.content }}</span>
                            </p>
                            <p class="mb-1">
                              <span class="font-weight-bold">Name: </span>
                              <span>{{ poster_ai.name }}</span>
                            </p>
                            <p class="mb-1">
                              <span class="font-weight-bold">Path: </span>
                              <span>{{ poster_ai.path }}</span>
                            </p>
                            <p class="mb-1">
                              <span class="font-weight-bold">Type: </span>
                              <span>{{ poster_ai.type }}</span>
                            </p>
                            <p class="mb-1">
                              <span class="font-weight-bold">Size: </span>
                              <span>{{ poster_ai.size }}</span>
                            </p>
                            <div class="" v-if="poster_ai.link_small !== null">
                              <p class="mb-1">
                                <span class="font-weight-bold">Preview: </span>
                                <a
                                  v-if="poster_ai.type === 'application/pdf'"
                                  class="d-block"
                                  style="width: 50%; cursor: auto"
                                >
                                  <pdf
                                    :src="poster_ai.link"
                                    style="width: 100%"
                                  ></pdf>
                                </a>
                                <a
                                  v-else
                                  class="d-block"
                                  style="width: 50%; cursor: auto"
                                >
                                  <img :src="poster_ai.link_small" style="width: 100%" />
                                </a>
                              </p>
                            </div>
                          </li>
                        </ul>
                        <ul v-else>
                          <li>
                            <em>No posters submitted</em>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div> -->


                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import firebase from "firebase";
import pdf from "vue-pdf";

export default {
  name: "SearchUser",
   components: {
    pdf,
  },
  data() {
    return {
      search_result: {},
      // posters_result: {},
      user: {
        uID: "",
        name: "",
        surname: "",
      },
      status: "",
      submitStatus: 1,
      posts: {
        submissions: [],
      },
      submissons_length: 0,
    };
  },
  computed: {
    id_current_edition() {
      return this.$store.getters.getIDCurrentEdition; // get ID current edition from store
    },
  },
  watch: {},
  created() {
    // this.getSubmissions();
  },
  methods: {
    searchUser() {
      if (this.user.uID !== "") {
        this.searchUserForUserID();
      } else if (this.user.name !== "") {
        this.searchUserForName();
      } else if (this.user.surname !== "") {
        this.searchUserForSurname();
      }
    },
    searchUserForUserID() {
      firebase
        .firestore()
        .collection("users")
        .where("uID", "==", this.user.uID)
        .get()
        .then((querySnapshot) => {
          let array_temp = [];
          querySnapshot.forEach((doc) => {
            let data = doc.data();
            let uID = doc.data().uID;
            // let ret = this.searchUserPosters(uID);
            let ret = this.getSubmissions(uID);
            data["posters_result"] = ret;
            array_temp.push(data);
          });
          // // console.log("Array temp:", array_temp);
          this.search_result = array_temp;
        })
        .catch(function (error) {
          console.error(error);
        });
    },
    searchUserForName() {
      firebase
        .firestore()
        .collection("users")
        .where("name", "==", this.user.name)
        .get()
        .then((querySnapshot) => {
          let array_temp = [];
          querySnapshot.forEach((doc) => {
            let data = doc.data();
            let uID = doc.data().uID;
            // let ret = this.searchUserPosters(uID);
            let ret = this.getSubmissions(uID);
            data["posters_result"] = ret;
            array_temp.push(data);
          });
          // // console.log("Array temp:", array_temp);
          this.search_result = array_temp;
        })
        .catch(function (error) {
          console.error(error);
        });
    },
    searchUserForSurname() {
      firebase
        .firestore()
        .collection("users")
        .where("surname", "==", this.user.surname)
        .get()
        .then((querySnapshot) => {
          let array_temp = [];
          querySnapshot.forEach((doc) => {
            let data = doc.data();
            let uID = doc.data().uID;
            // let ret = this.searchUserPosters(uID);
            let ret = this.getSubmissions(uID);
            data["posters_result"] = ret;
            array_temp.push(data);
          });
          // // console.log("Array temp:", array_temp);
          this.search_result = array_temp;
        })
        .catch(function (error) {
          console.error(error);
        });
    },

    searchUserPosters(user_id) {
      // console.log("SEARCH USER POSTERS - User ID: ", user_id);
      const submissions = [];
      firebase
        .firestore()
        .collection("submissions")
        // .where("uID", "==", "ZqV3ZWqPG9TBRftex20AlmCSv3i2")
        .where("uID", "==", user_id)
        .get()
        .then((querySnapshot) => {
          // const submissions = [];
          querySnapshot.forEach((doc) => {
            const submission = {
              // posters: doc.data().posters,
              posters: doc.data().editions[this.id_current_edition].posters,
            };
            // // console.log(doc.data().posters);
            submissions.push(submission);
          });
          // this.posters_result = submissions;
        })
        .catch(function (error) {
          console.error(error);
        });

      // console.log("submissions: ", submissions);
      return submissions;
    },

    getSubmissions(user_id) {
      // console.log("SEARCH USER POSTERS - User ID: ", user_id);
      const submissions = [];

      // Retrieve the submission info
      firebase
        .firestore()
        .collection("submissions")
        // .where("uID", "==", this.$store.state.user.uID)
        .where("uID", "==", user_id)
        .get()
        .then((querySnapshot) => {
          // const submissions = [];
          querySnapshot.forEach((doc) => {
            // console.log("DOC: ", doc.data());
            const submission = {
              posters: doc.data().editions[this.id_current_edition].posters,
              // posters_ai: doc.data().editions[this.id_current_edition].posters_AI,
            };
            // console.log("submission: ", submission);
            submissions.push(submission);
          });
          this.posts.submissions = submissions;
          // let sub = submissions[0].posters;
          // // console.log('SUBMISSIONSS: ',sub)
          // this.submissons_length = sub.length;
        })
        .then(() => {
          let submissions = this.posts.submissions[0].posters;
          // // console.log('SUBMISSIONSS: ',submissions)
          this.submissons_length = submissions.length;
          // // console.log('len: ', this.submissons_length);

          for (let i = 0; i < this.submissons_length; i++) {
            let path = submissions[i].path;
            // if (!this.media.url) {
            this.getDownloadUrl(i, path);
            // }
          }
        });

      // console.log("submissions: ", submissions);
      return submissions;
    },
    getDownloadUrl: function (index, path) {
      // console.log("index: ", index);
      // console.log("path: ", path);
      // this.type = this.media.type;
      firebase
        .storage()
        .ref(path)
        .getDownloadURL()
        .then((url) => {
          // this.files.url.push(url);
          let single_poster = this.posts.submissions[0].posters[index];
          // console.log("single_poster: ", single_poster);
          // single_poster.path = url;
          single_poster.link = url;
        })
        .catch(() => {
          // this.file.url = "error";
          // this.files.push("error");
        });
    },
  },
};
</script>


<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped>
</style>
