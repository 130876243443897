<template>
  <div id="analyticsSubmissions">
    <div class="row">
      <div class="col-12">

        <!-- <pre>
          {{submissions}}
        </pre> -->

        <p>
          <b>Number of Subscribers:</b>
          <!-- {{ getNumSub }} -->
          {{ submissions.length }}
        </p>
        <hr>
        <!-- <p>
          <b>Number of posters:</b>
          {{ getNumPoster }}
        </p> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "analyticsSubmissions",
  props: {
    submissions: {
      type: Array,
      required: true
    }
  },
  data() {
    return {};
  },
  computed: {
    getNumSub() {
      // Calculate the number of people
      // // console.log("getNumSub");
      return this.submissions.length;
    },
    getNumPoster() {
      // Calculate the number of people
      // console.log("getNumPoster");
      let numberPoster = 0;
      for (let i = 0; i < this.submissions.length; i++) {
        numberPoster += this.submissions[i].posters.length;
      }
      return numberPoster;
    }
   
  }
  // filters: {
  //   toPerc: function(values, my_gender) {
  //     // console.log("toPerc");
  //     return ((my_gender/values.length)*100).toFixed() + "%";
  //   }
  // }
};
</script>

<style scoped>
</style>
