
<template>
  <div class="media" style="width: 100%">
    <!-- {{media.path}} -->

    <div v-if="media.path === 'error'">
      <img src="../../assets/placeholder_broken.png" style="width: 100%" />
    </div>
    <div v-else style="width: 100%">
      <div v-if="media.link">
        <!-- Poster -->
        <div>
          <!-- Pdf -->
          <div v-if="media.type === 'application/pdf'">
            <!-- Original: -->
            <a
              style="width: 100%"
              @click="
                url = media.link;
                type = media.type;
              "
            >
              <pdf :src="media.link" style="width: 100%"></pdf>
            </a>
          </div>
          <!-- Image -->
          <div v-else style="width: 100%">
            <!-- Thumbnails: -->
            <a
              v-if="media.link_big"
              style="width: 100%"
              @click="
                url = media.link_big;
                type = media.type;
              "
            >
              <img class="w-100 border-black"  :src="media.link_big" />
            </a>
            <!-- Original: -->
            <a
              v-else
              style="width: 100%"
              @click="
                url = media.link_original;
                type = media.type;
              "
            >
              <img class="w-100 border-black"  :src="media.link_original" />
            </a>
          </div>
        </div>
      </div>
      <div v-else>
        <img class="p-5" src="../../assets/ajax-loader.gif" />
      </div>
    </div>

    <!-- Lightbox -->
    <div
      class="lightbox"
      v-if="url"
      @click="
        url = null;
        type = null;
      "
    >
      <!-- Pdf -->
      <span v-if="media.type === 'application/pdf'">
        <div class="h-100 d-flex align-items-center justify-content-center">
          <!-- Original: -->
          <pdf :src="url" style="max-height: 100%; max-width: 100%"></pdf>
        </div>
      </span>
      <!-- Image -->
      <span v-else>
        <div class="h-100 d-flex align-items-center justify-content-center">
          <!-- Thumbnails: -->
          <img
            v-if="media.link_big"
            :src="media.link_big"
            style="max-height: 100%; max-width: 100%"
          />
          <!-- Original: -->
          <img
            v-else
            :src="media.link_original"
            style="max-height: 100%; max-width: 100%"
          />
        </div>
      </span>
    </div>
  </div>
</template>

<script>
import firebase from "firebase";
import pdf from "vue-pdf";

export default {
  name: "Media",
  components: {
    pdf,
  },
  props: {
    media: Object,
  },
  data() {
    return {
      url: null,
      type: null,
    };
  },
  beforeMount() {
    if (!this.media.link) {
      this.getDownloadUrl(this.media.path);
    }
  },
  beforeUpdate() {
    if (!this.media.link) {
      this.getDownloadUrl(this.media.path);
    }
  },
  methods: {
    getDownloadUrl: function (path) {
      this.type = this.media.type;
      firebase
        .storage()
        .ref(path)
        .getDownloadURL()
        .then((url) => {
          this.media.link = url;
        })
        .catch(() => {
          this.media.link = "error";
        });
    },
  },
};
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped>
/* .media { */
/* line-height: 0; */
/* } */

/* pic */
a:hover {
  cursor: zoom-in;
}

/* pic lightbox */
.lightbox {
  background: #000;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  cursor: zoom-out;
  padding: 2rem;
  z-index: 10;
}

.lightbox img {
  max-width: 100%;
  max-height: 100%;
}
</style>
