
<template>
  <div id="user" class="row padding-for-header my-5">
    <div v-if="user_is_active" class="col-12">
      
      <!-- <div class="page-title row mb-5 mt-5 pb-5 pt-5">
        <div class="col-10 offset-1">
          <h1 class="">Hello</h1>
          <p>
            <Logout />
          </p>
        </div>
      </div> -->

      <div class="row">
        <div class="col-12">
          <div class="row mb-5">
            <div class="col-12 col-lg-10 offset-lg-1 text-left">
              <h1 class="text-uppercase">
                Hello
              </h1>
              <p>
                <Logout />
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="row filters justify-content-between pb-3 mb-5">
        <div class="col-12 col-lg-10 offset-lg-1 text-left">
          <div class="row">
            <div class="col-12 col-lg-4">
              <a
                class="d-block w-100 font-size-2rem text-uppercase border-bottom-black py-1"
                :class="{ active: category === 'read' }"
                v-on:click="category = 'read'"
                >Posters</a
              >
            </div>
            <div class="col-12 col-lg-4">
              <a
                class="d-block w-100 font-size-2rem text-uppercase border-bottom-black py-1"
                :class="{ active: category === 'upload' }"
                v-on:click="category = 'upload'"
                >Upload</a
              >
            </div>
            <div class="col-12 col-lg-4">
              <a
                class="d-block w-100 font-size-2rem text-uppercase border-bottom-black py-1"
                :class="{ active: category === 'anagraphical' }"
                v-on:click="category = 'anagraphical'"
                >Anagraphical</a
              >
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <!-- <div v-if="empty">
          <Upload />
        </div>
        <div v-else>
          <Read />
        </div> -->
        <div class="col-12 col-lg-10 offset-lg-1">
          <components v-bind:is="component" />
        </div>
      </div>
    </div>
    <div v-else class="col-12">
      <!-- <ThankYou /> -->
      <Maintenance />
    </div>
  </div>
</template>

<script>
import store from "@/store";
import firebase from "firebase";
import Anagraphical from "@/components/Content/User/Anagraphical";
import Read from "@/components/Content/User/Read";
import Upload from "@/components/Content/User/Upload";
import Logout from "@/components/Auth/Logout";
// import ThankYou from "@/components/ThankYou";
import Maintenance from "@/components/Maintenance";

export default {
  name: "User",
  components: {
    Anagraphical,
    Read,
    Upload,
    Logout,
    // ThankYou,
    Maintenance,
  },
  data() {
    return {
      empty: false,
      category: "",
      component: "",
      posts: {
        // users: [],
        submissions: [],
      },
    };
  },
  computed: {
    user_is_active() {
      return this.$store.state.options.roles_active.user_can_login; // user is active?
    },
    user_info() {
      return this.$store.state.user; // get user from store
    },
  },
  watch: {
    category() {
      this.component = this.category;
    },
  },
  created() {
    const self = this;
    self.checkIfExists();
    // Carico nello store le submissions dell'utente loggato
    self.getUserSubmissions();
  },
  methods: {
    checkIfExists() {
      const self = this;

      firebase
        .firestore()
        .collection("submissions")
        .where("uID", "==", this.$store.state.user.uID)
        .get()
        .then((querySnapshot) => {
          if (querySnapshot.empty) {
            self.empty = true;
          }
        });
      // console.log("EXIST!");
    },
    getUserSubmissions() {
      // Retrieve the submission info
      firebase
        .firestore()
        .collection("submissions")
        .where("uID", "==", this.user_info.uID)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            // console.log(doc.data());
            store.commit("setPosters", doc.data());
          });
        })
        .then( () => {
          this.component = 'read'; 
          this.category = 'read';
        })

      // console.log("SUBMISSION!");
    },
  },
};
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped>
</style>
