<template>
  <div id="analyticsUsers">
    <div class="row">
      <div class="col-12">
        <!-- <pre>
          USERS
          {{users}}
        </pre> -->
        <!-- <pre>
          NATIONALITY
          {{nationality}}
        </pre> -->

        <b>Number of participants:</b>
        {{ users.length }}
        <hr />
        <!-- <p>
          <b>Medium age:</b>
          {{ calcMedAge }}
        </p> -->
        <div class="mb-3">
          <button
            type="button"
            class="btn btn-light mr-3"
            style="padding: 0.25rem 0.5rem"
            v-on:click="viewAge = !viewAge"
          >
            &#9660;
          </button>
          <b>Age</b>
          <br />
          <span v-if="viewAge">
            <div class="pt-2" style="padding-left: 60px">
              Medium age is: {{ calcMedAge }}
            </div>
          </span>
        </div>
        <hr />
        <div class="mb-3">
          <button
            type="button"
            class="btn btn-light mr-3"
            style="padding: 0.25rem 0.5rem"
            v-on:click="getGender"
          >
            &#9660;
          </button>
          <b>Gender</b>
          <br />
          <span v-if="viewG">
            <!-- Chiave = gender -->
            <div
              class="pt-2"
              style="padding-left: 60px"
              v-for="(valore, chiave) in gender"
              :key="chiave.index"
            >
              {{ chiave }}: {{ users | toPerc(valore) }}
            </div>
          </span>
        </div>
        <hr />
        <button
          type="button"
          class="btn btn-light mr-3"
          style="padding: 0.25rem 0.5rem"
          v-on:click="viewNa = !viewNa"
        >
          &#9660;
        </button>
        <b>{{ Object.keys(nationality).length }}</b> Different Nations
        <br />
        <div v-if="viewNa">
          <div class="row mb-5">
            <div class="col">
              <!-- debug: sort={{ currentSort }}, dir={{ currentSortDir }} -->
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th v-on:click="sortBy('nation')">Nation</th>
                    <th v-on:click="sortBy('participants')">Participants</th>
                    <th v-on:click="sortBy('percentage')">Percentage</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="nation in currentPageNations" :key="nation.id">
                    <td>{{ nation.name }}</td>
                    <td>{{ nation.value }}</td>
                    <td>{{ nation.perc }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!-- pagination -->
          <div class="row mb-5">
            <div class="col text-left">
              <button
                class="pag btn border"
                :class="currentPage['p'] === 0 ? 'd-none' : 'd-inline'"
                :disabled="currentPage === 0"
                @click="prevPage"
              >
                &laquo;
              </button>
            </div>
            <div class="col text-center">
              Pagina {{ currentPage + 1 }} di {{ lastPage + 1 }}
            </div>
            <div class="col text-right">
              <button
                class="pag btn border"
                :class="currentPage['p'] === lastPage ? 'd-none' : 'd-inline'"
                :disabled="currentPage === lastPage"
                @click="nextPage"
              >
                &raquo;
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "analyticsUsers",
  props: {
    users: {
      type: Array,
      required: true,
    },
    nationality: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      gender: {},
      mediumAge: 0,
      viewAge: false,
      viewNa: false,
      viewG: false,
      currentPage: 0,
      perPage: 10,
      currentSort: "nation",
      currentSortDir: "asc",
    };
  },
  computed: {
    calcMedAge: function () {
      let sum = 0;
      for (let i = 0; i < this.users.length; i++) {
        sum += this.calcAge(this.users[i].dateOfBirth);
      }
      let mediumAge = (sum / this.users.length).toFixed();
      return mediumAge;
    },
    lastPage: function () {
      return Math.ceil(this.nationality.length / this.perPage) - 1;
    },
    currentPageNations: function () {
      // // console.log("currentPageNations");

      let currentPageItems = [];
      const start = this.currentPage * this.perPage;
      var end = start + this.perPage;

      if (this.currentPage === this.lastPage) {
        end = start + (this.nationality.length % this.perPage);
      }

      for (let i = start; i < end; i++) {
        currentPageItems.push(this.nationality[i]);
      }
      return currentPageItems;
    },
  },
  filters: {
    toPerc: function (values, myValue) {
      // // console.log("toPerc");
      return ((myValue / values.length) * 100).toFixed(2) + "%";
    },
  },
  methods: {
    // calcAge: function(value) {
    //   // console.log(value)
    //   // var year = Number(value.substr(0, 4));
    //   // var month = Number(value.substr(5, 2));
    //   // var day = Number(value.substr(8, 2));
    //   var day = Number(value.substr(0, 2));
    //   // console.log("day: ",day);
    //   var month = Number(value.substr(3, 2));
    //   // console.log("month: ",month);
    //   var year = Number(value.substr(6, 4));
    //   // console.log("year: ",year);

    //   var today = new Date();
    //   var todayMonth = today.getMonth() + 1;

    //   var age = today.getFullYear() - year;
    //   if (todayMonth < month || (todayMonth === month && today.getDate() < day)) {
    //     age--;
    //   }
    //   // console.log('age: ',age);
    //   return age;
    // },
    calcAge: function (value) {
      var from = value.split("/");
      var birthdateTimeStamp = new Date(from[2], from[1] - 1, from[0]);
      var cur = new Date();
      var diff = cur - birthdateTimeStamp;
      // This is the difference in milliseconds
      var currentAge = Math.floor(diff / 31557600000);
      // Divide by 1000*60*60*24*365.25

      // // console.log(currentAge);
      return currentAge;
    },
    sortBy: function (sortKey) {
      if (sortKey === this.currentSort) {
        this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
      }
      this.currentSort = sortKey;
    },
    nextPage: function () {
      this.currentPage++;
    },
    prevPage: function () {
      this.currentPage--;
    },
    // Calculate the genders
    getGender: function () {
      this.viewG = !this.viewG;
      const gender = {};
      for (let i = 0; i < this.users.length; i++) {
        const _gender = this.users[i].gender;

        if (_gender === "") {
          gender["Other"] ? gender["Other"]++ : (gender["Other"] = 1);
        } else {
          gender[_gender] ? gender[_gender]++ : (gender[_gender] = 1);
        }
        this.gender = gender;
      }
    },
  },
};
</script>

<style scoped>
.pag {
  background: transparent;
  color: #da265d;
}
.pag:hover {
  background: #da265d;
  color: black;
}
a:hover {
  font-weight: bold;
}
</style>
