
<template>
  <div id="jury">
    <div v-if="jury_is_active" class="row mb-5">
      <div class="col-10 offset-1">
        <!-- Header -->
        <div class="page-title row mt-5 pt-5">
          <div class="col-12">
            <h1 class="">Vote</h1>
          </div>
        </div>

        <div v-if="!submitted">
          <!-- Filters -->
          <div class="filters row mb-5">
            <div class="col-12">
              <div
                class="d-flex justify-content-between border-bottom-black pb-2"
              >
                <div class="">
                  <span class="text-uppercase mr-2">See</span>
                  <a
                    class="text-uppercase mb-0 active"
                    :class="{ active: category === 'poster' }"
                    @click="(category = 'poster'), (pagination.page = 0)"
                    >Posters</a
                  >
                </div>

                <div class="">
                  <p class="text-uppercase mb-0">
                    Voted
                    <b>{{ rated }}</b>
                    / {{ medias.length }}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <!-- Medias -->
          <div class="row mb-5">
            <div
              class="col-12 col-md-6 col-lg-4"
              :class="{ 'col-xl-3': media.category == 'poster' }"
              v-for="(media, index) in paginatedData"
              :key="index"
            >
              <!-- {{ media }} -->
              <!-- <div v-if="!media.url">
              <img class="p-5" src="../../assets/ajax-loader.gif" />
            </div> -->
              <!-- <div v-else class="mb-3"> -->
              <div class="pb-4 mb-5">
                <!-- <p>MEDIA</p> -->
                <!-- {{media}} -->
                <media :media="media" />

                <div class="border-right-black border-bottom-black border-left-black p-3">
                  <div class="mb-2">
                    <span class="d-block text-uppercase">Aesthetic impact</span>
                  </div>
                  <div class="rates">
                    <a
                      v-for="index1 in 5"
                      :key="index1.index"
                      :class="{ active: index1 === media.rate.aesthetics }"
                      @click="setRate(index, index1, 'aesthetics')"
                      >{{ index1 }}</a
                    >
                  </div>
                </div>
                <div class="border-right-black border-bottom-black border-left-black p-3">
                  <div class="mb-2">
                    <span class="d-block text-uppercase">Coherence with theme</span>
                  </div>
                  <div class="rates">
                    <a
                      v-for="index2 in 5"
                      :key="index2.index"
                      :class="{ active: index2 === media.rate.theme }"
                      @click="setRate(index, index2, 'theme')"
                      >{{ index2 }}</a
                    >
                  </div>
                </div>
                <div class="border-right-black border-bottom-black border-left-black p-3">
                  <div class="mb-2">
                    <span class="d-block text-uppercase">Originality</span>
                  </div>
                  <div class="rates">
                    <a
                      v-for="index3 in 5"
                      :key="index3.index"
                      :class="{ active: index3 === media.rate.originality }"
                      @click="setRate(index, index3, 'originality')"
                      >{{ index3 }}</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Pagination -->
          <div class="row mb-5">
            <!-- PREV -->
            <div class="col text-left">
              <button
                class="btn"
                :class="pagination.page === 0 ? 'd-none' : 'd-inline'"
                :disabled="pagination.page === 0"
                @click="prevPage"
              >
                &laquo;
              </button>
            </div>

            <div
              v-if="Number.isInteger(medias.length / pagination.postsPerPage)"
              class="col text-center"
            >
              <span class="text-uppercase">
                Pages 
              </span>
               {{ pagination.page + 1 }} /
              {{
                Math.trunc(medias.length / pagination.postsPerPage) | toFixed
              }}
            </div>
            <div v-else class="col text-center">
              <span class="text-uppercase">
                Pages 
              </span> 
              {{ pagination.page + 1 }} /
              {{
                (Math.trunc(medias.length / pagination.postsPerPage) + 1)
                  | toFixed
              }}
            </div>

            <!-- NEXT -->
            <div class="col text-right">
              <button
                class="btn"
                :class="
                  pagination.page >= pageCount - 1 ? 'd-none' : 'd-inline'
                "
                :disabled="pagination.page >= pageCount - 1"
                @click="nextPage"
              >
                &raquo;
              </button>
            </div>
          </div>

          <!-- Select page -->
          <div class="row mb-5">
            <div class="col">
              <label class="text-uppercase mr-3" for="pages">Select page:</label>
              <input
                class="border-black"
                type="number"
                id="pages"
                name="pages"
                value=""
                v-model="inputPaginationNumber"
                min="1"
                :max="pageCount"
              />
              <button class="btn p-0 m-0" @click="changePage()">
              <p class="px-2 mx-2 mb-2">></p></button>
            </div>
          </div>

          <!-- Submit -->
          <div class="submit row flex-row-reverse border-bottom-black">
            <div class="col mb-3 text-right">
              <input
                type="submit"
                class="btn"
                @click="save()"
                :class="submitStatus == 1 ? 'btn-secondary' : 'btn-primary'"
                :value="submitStatus == 1 ? 'Saving' : 'Save & Continue'"
                :disabled="submitStatus == 1 ? true : false"
              />
              <br />
              <small>You can resume voting later</small>
            </div>
            <div class="col-1"></div>
            <div class="col mb-3 text-left">
              <input
                type="submit"
                class="btn"
                @click="save('submit')"
                :class="submitStatus == 2 ? 'btn-secondary' : 'btn-primary'"
                :value="
                  submitStatus == 2
                    ? 'Saving & Submitting'
                    : 'Save &amp; Submit'
                "
                :disabled="submitStatus == 2 ? true : false"
              />
              <br />
              <small>You can’t modify your votation any more</small>
            </div>
          </div>
        </div>

        <div v-else>
          <div class="row">
            <div class="col-12 py-3 mb-5">
              <h2 class="text-uppercase">
                The selection has already been submitted
              </h2>
            </div>
          </div>
        </div>

        <!-- Logout -->
        <div class="row border-top pt-3">
          <div class="col-12">
            <Logout />
          </div>
        </div>
      </div>
      <!-- Col-10 offset-1 -->
    </div>
    <div v-else>
      <ThankYou />
    </div>
  </div>
</template>

<script>
import firebase from "firebase";
import Media from "@/components/Inc/MediaJury";
import Logout from "@/components/Auth/Logout";
import ThankYou from "@/components/ThankYou";

export default {
  name: "Jury",
  components: {
    Media,
    Logout,
    ThankYou,
  },
  data() {
    return {
      user: this.$store.state.user,
      posters: [],
      medias: [],
      record_id: "",
      category: "all",
      pagination: {
        page: 0,
        postsPerPage: 9,
      },
      inputPaginationNumber: 1,
      submitted: false,
      submitStatus: 0,
      juryDocID: [],
      jury_exist: false,
      // ratings_record_id: true,
      IDdoc: [],
      myDoc: "",
    };
  },

  filters: {
    toFixed: function (value) {
      if (value < 1) {
        return 1;
      }
      return value.toFixed(0);
    },
  },
  computed: {
    jury_is_active() {
      return this.$store.state.options.roles_active.jury; // jury is active?
    },
    user_info() {
      return this.$store.state.user; // get user from store
    },
    editions() {
      return this.$store.state.options.contest_editions; // get editions from store
    },
    rated: function () {
      let i = 0;
      this.medias.forEach((media) => {
        if (
          media.rate.aesthetics !== 0 &&
          media.rate.originality !== 0 &&
          media.rate.theme !== 0 &&
          media.rate.total !== 0
        ) {
          i++;
        }
      });
      return i;
    },
    pageCount: function () {
      const l = this.medias.length;
      const s = this.pagination.postsPerPage;
      return Math.ceil(l / s);
    },
    paginatedData: function () {
      const self = this;
      // Category first
      self.medias = self.posters;
      // Then paginate
      const start = this.pagination.page * this.pagination.postsPerPage;
      const end = start + this.pagination.postsPerPage;
      return this.medias.slice(start, end);
    },
  },

  created() {
    const self = this;
    // self.getID();
    // self.getPosts();
    self.getID().then(function () {
      self.getPosts();
    });
  },

  methods: {
    // getID() {
    getID: function () {
      const self = this;

      // console.log('--- GET ID ---');

      const user_id = this.$store.state.user.uID;
      // console.log('user id: ', user_id);

      return new Promise((resolve, reject) => {
        const dbRef = firebase.firestore().collection("ratings");
        dbRef
          // .where("uID", "==", this.user.uID)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              // self.ratings_record_id = doc.id;
              // console.log("doc id: ", doc.id);
              this.IDdoc.push(doc.id);
              // console.log("jury id: ", doc.data().uID_jury);
              this.juryDocID.push(doc.data().uID_jury);

              if (doc.data().uID_jury === user_id) {
                this.myDoc = doc.id;
                // console.log("MYDOC: ", this.myDoc);
              }
            });
          })
          // .then(docRef => resolve(docRef))
          .then(() => {
            resolve("RESOLVE");
          })
          .catch((error) => reject(error));
      });
    },

    getPosts() {
      const self = this;

      // console.log('--- GET POSTS ---');

      // I Get Ratings Record First
      firebase
        .firestore()
        .collection("ratings")
        // .where("uID_jury", "==", self.$store.state.user.uID)
        // .where("year", "==", self.$store.state.options.year)
        .where("uID_jury", "==", self.user_info.uID)
        // .where("edition_ID", "==", self.editions.contest_editions.current_edition.edition_ID)
        .get()
        .then((querySnapshot) => {
          // console.log('query Snapshot is empty: ', querySnapshot.empty);

          // Controllo se c'è già un record
          if (querySnapshot.empty) {
            // console.log("Prima volta");
            // Prima volta: prendo tutti i finalisti
            firebase
              .firestore()
              .collection("finalists")
              // .where("year", "==", self.$store.state.options.year)
              .where(
                "edition_ID",
                "==",
                self.editions.current_edition.edition_ID
              )
              .get()
              .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                  if (!doc.data().submitted === true) {
                    alert(
                      "YOU CANNOT VOTE YET: the selection has not been completed yet!"
                    );
                  } else {
                    const posters = doc.data().posters_finalists;
                    let rates = {
                      aesthetics: 0,
                      theme: 0,
                      originality: 0,
                      total: 0,
                    };
                    posters.forEach((poster) => {
                      poster["rate"] = { ...rates };
                    });
                    self.posters = posters;
                  }
                });
              });
          } else {
            // Seconda volta: prendo il record
            // console.log("Seconda volta");
            querySnapshot.forEach((doc) => {
              self.record_id = doc.id;
              const data = doc.data();
              self.submitted = data.submitted;
              // Controllo che non sia già stato submittato
              if (!data.submitted) {
                // Ciclo i poster
                data.posters.forEach((poster) => {
                  self.posters.push(poster);
                });
              }
            });
          }
        });
    },

    save(action) {
      const self = this;

      if (action === "submit") {
        // Ciclo l'array poster per verificare che tutti i poster abbiano ricevuto un voto per ogni categoria
        let all_posters_are_rated = true;
        for (let i = 0; i < self.posters.length; i++) {
          // console.log("poster: ", self.posters[i].rate.total);
          if (
            self.posters[i].rate.aesthetics < 1 ||
            self.posters[i].rate.theme < 1 ||
            self.posters[i].rate.originality < 1 ||
            self.posters[i].rate.total < 1
          ) {
            let check_page = Math.trunc(i / this.pagination.postsPerPage);
            // console.log("I poster non sono stati votati tutti. Controlla pagina: ", check_page + 1);
            alert(
              "You forget to vote all the posters, so you can’t save it. \n Check the page: " +
                (check_page + 1)
            );
            all_posters_are_rated = false;
            break;
          }
        }

        if (all_posters_are_rated) {
          if (
            confirm(
              "Are you sure to save and complete? You can’t change your vote any more."
            )
          ) {
            this.submitted = true;
            this.submitStatus = 2;
          }
        } else {
          // alert("You forget to vote all the posters, so you can’t save it.");
          this.submitStatus = 0;
        }
      } else {
        this.submitStatus = 1;
      }

      if (this.submitStatus > 0) {
        self.update().then(() => {
          self.$router.go();
        });
      }
    },

    update() {
      const self = this;

      let variabile = this.$store.state.user.uID;
      // console.log("JuryID: ", variabile);

      this.jury_exist = this.juryDocID.includes(variabile);
      // console.log("Jury exist: ", this.jury_exist);

      // console.log("thisMyDoc: ", this.myDoc);

      return new Promise((resolve, reject) => {
        if (this.jury_exist) {
          firebase
            .firestore()
            .collection("ratings")
            .doc(this.myDoc)
            .update({
              posters: this.posters,
              submitted: this.submitted,
            })
            .then((docRef) => resolve(docRef))
            .catch((error) => reject(error));
        } else {
          // Create the record
          firebase
            .firestore()
            .collection("ratings")
            .add({
              // posters: this.posters,
              posters: self.posters,
              // uID_jury: this.$store.state.user.uID,
              uID_jury: self.user_info.uID,
              // year: this.$store.state.options.year,
              edition_ID: self.editions.current_edition.edition_ID,
              submitted: this.submitted,
            })
            .then((docRef) => resolve(docRef))
            .catch((error) => reject(error));
        }
      });
    },

    // SET RATE
    setRate(index, value, type) {
      // console.log('index: ',index);
      // console.log('value: ',value);
      // console.log('type: ',type);
      // console.log('medias[index]: ',this.medias[index]);

      index += this.pagination.page * this.pagination.postsPerPage;
      // this.medias[index]["rate"] = value;
      this.medias[index]["rate"][type] = value;
      // // console.log('typeof: ',typeof(this.medias[index]["rate"][type]));
      // // console.log('typeof: ',typeof(value));

      // this.medias[index]["rate"].total += value;
      this.medias[index]["rate"].total =
        this.medias[index]["rate"].aesthetics +
        this.medias[index]["rate"].theme +
        this.medias[index]["rate"].originality;
    },

    /*
    Pagination: prev
    */
    prevPage() {
      this.pagination.page--;
    },

    /*
    Pagination: next
    */
    nextPage() {
      this.pagination.page++;
    },

    /*
    Pagination: change
    */
    changePage() {
      const self = this;
      const number_on_input = self.inputPaginationNumber;
      const number_of_pages =
        (Math.trunc(self.medias.length / self.pagination.postsPerPage) + 1) |
        self.toFixed;
      if (number_on_input > 0 && number_on_input <= number_of_pages) {
        this.pagination.page = Number(pages.value - 1);
      }
    },
  },
};
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped>
/* filters */
.filters a {
  cursor: pointer;
}
.filters a.active {
  font-weight: 700;
}

/* rates pic */
.rates {
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
}
.rates a {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  border: 1px #da265d solid;
  border-radius: 5px;
  color: #da265d;
  padding: 0.25rem 0.5rem;
  /* margin: 0 0.5rem; */
  min-width: 36px;
}
.rates a:hover {
  cursor: pointer;
  background: #da265d;
  color: #fff !important;
}
.rates a.active {
  background: #da265d;
  color: #fff !important;
  font-weight: 700;
}
</style>
