import Vue from 'vue'
import VueRouter from 'vue-router'
import Vuex from 'vuex'

// Firebase
import firebase from 'firebase'

// External components
import VueResource from 'vue-resource'
import VueTelInput from 'vue-tel-input'
// import VeeValidate from 'vee-validate'
// import VueMoment from 'moment'

// Views
import LoginPage from '../views/LoginPage.vue'
import RegisterPage from '../views/RegisterPage.vue'
import Dashboard from '../views/Dashboard.vue'


Vue.use(VueRouter);
Vue.use(Vuex);
Vue.use(VueResource);
Vue.use(VueTelInput);
// Vue.use(VeeValidate);
// Vue.use(VueMoment);


// import VuePhoneNumberInput from 'vue-phone-number-input';
// import 'vue-phone-number-input/dist/vue-phone-number-input.css';
// Vue.component('vue-phone-number-input', VuePhoneNumberInput);


const routes = [
    // Auth: Login
    {
        path: '/login',
        name: 'loginPage',
        component: LoginPage
    },
    // Auth: Register
    {
        path: '/register',
        name: 'registerPage',
        component: RegisterPage
    },
    // Home
    {
        path: '/',
        name: 'dashboard',
        component: Dashboard,
        meta: { requiresAuth: true }
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})


// https://www.freecodecamp.org/news/how-to-add-authentication-to-a-vue-app-using-firebase/
router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        // this route requires auth, check if logged in
        // if not, redirect to custom page.
        firebase.auth().onAuthStateChanged(function (user) {
            if (user) {
                next()
            } else {
                next({
                    name: 'loginPage',
                })
            }
        })
    } else {
        next() // make sure to always call next()!
    }
})


export default router