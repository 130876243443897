<template>
  <div id="settings">
    <div class="">
      <div class="page-title row mb-5 mt-5">
        <div class="col-10 offset-1">
          <h2 class="font-weight-bold">Settings</h2>
          <!-- <p class="strong">Don't forget to fill all the fields!</p> -->
        </div>
      </div>

      <!-- CONTEST -->
      <div>
        <div class="row">
          <aside class="col-12 col-lg-4 col-xl-3 offset-xl-1">
            <h2>Contest</h2>
          </aside>
          <div class="col-12 col-lg-8 offset-lg-4 col-xl-7 offset-xl-0 mb-4">
            <!-- Status -->
            <div class="mb-4">
              <h2 class="d-inline-block">Contest is:</h2>
              <h2
                class="d-inline-block font-weight-bold px-2"
                v-if="contest_is_active"
              >
                Open
              </h2>
              <h2 class="d-inline-block font-weight-bold px-2" v-else>
                Closed
              </h2>
            </div>

            <!-- Open contest -->
            <div class="mb-4">
              <div class="border p-3 mb-4">
                <p class="font-weight-bold">Crea una nuova edizione</p>
                <p class="">
                  Inserire l'dentificativo per la nuova edizione. L'ID dovrà
                  essere univoco, non inserire un ID utilizzato nelle vecchie
                  versioni. Per le edizioni passate è stato utilizzato l'anno
                  (es: 2020).
                  <br />
                  Il seguente ID verrà utilizzato per creare la nuova cartella
                  nello storage che conterrà tutti i poster della nuova edizione
                  e verrà utilizzato per identificare i poster dell'utente
                  all'interno del database.<br />
                </p>
                <div></div>
                <!-- Folder name -->
                <span class="d-inline-block">ph_</span>
                <input
                  class="d-inline-block form-control w-auto mb-4"
                  type="text"
                  name="edition_id"
                  v-model="edition_ID_without_ph"
                  required
                  :disabled="contest_is_active === true"
                />
                <!-- Button -->
                <input
                  type="button"
                  class="btn btn-primary w-100"
                  value="Start new edition"
                  @click="startNewEdition()"
                  :disabled="contest_is_active === true"
                />
              </div>
            </div>
            <!-- Close contest -->
            <div class="mb-5">
              <div class="border p-3 mb-4">
                <p class="font-weight-bold">Archivia la vecchia edizione</p>
                <p class="">
                  Questa operazione impedirà l'accesso a tutti gli utenti
                  (tranne all'admin) e salverà le informazioni di questa
                  edizione tra quelle passate. Poi rimuoverà tutti i poster che
                  non sono stati inseriti tra i finalisti.
                </p>
                <!-- Button -->
                <input
                  type="button"
                  class="btn btn-primary w-100"
                  value="Archive old edition"
                  @click="archiveOldEdition()"
                  :disabled="contest_is_active === false"
                />
              </div>
            </div>
            <!-- Status -->
            <div
              class="mb-5 bg-secondary text-white p-3 border mb-3 rounded"
              v-html="contestStatus"
              v-if="contestStatus"
            ></div>
          </div>
        </div>
      </div>

      <form class="" @submit.prevent="saveSettings">
        <!-- ROLES -->
        <div class="row form-group">
          <aside class="col-12 col-lg-4 col-xl-3 offset-xl-1">
            <h2>Roles</h2>
          </aside>
          <div class="col-12 col-lg-8 offset-lg-4 col-xl-7 offset-xl-0 mb-4">
            <!-- Active contest -->
            <!-- <div class="mb-4">
              <div class="custom-control custom-switch">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="switchContest"
                  v-model="contest_is_active"
                />
                <label class="custom-control-label" for="switchContest"
                  >Concorso aperto</label
                >
                <small class="d-block"
                  >Blocca il login a tutti gli utenti (tranne l'admin)</small
                >
              </div>
            </div> -->
            <div>
              <!-- Active roles -->
              <div class="mb-4">
                <div class="custom-control custom-switch">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="switchUserLogin"
                    v-model="roles.user_can_login"
                    :disabled="contest_is_active === false"
                  />
                  <label class="custom-control-label" for="switchUserLogin"
                    >User can login</label
                  >
                </div>
                <div class="custom-control custom-switch">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="switchUserRegister"
                    v-model="roles.user_can_register"
                    :disabled="contest_is_active === false"
                  />
                  <label class="custom-control-label" for="switchUserRegister"
                    >User can register</label
                  >
                </div>
                <div class="custom-control custom-switch">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="switchUserUploaded"
                    v-model="roles.user_can_upload"
                    :disabled="contest_is_active === false"
                  />
                  <label class="custom-control-label" for="switchUserUploaded"
                    >User can upload</label
                  >
                </div>
                <div class="custom-control custom-switch">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="switchSelector"
                    v-model="roles.selector"
                    :disabled="contest_is_active === false"
                  />
                  <label class="custom-control-label" for="switchSelector"
                    >Selector</label
                  >
                </div>
                <div class="custom-control custom-switch">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="switchJury"
                    v-model="roles.jury"
                    :disabled="contest_is_active === false"
                  />
                  <label class="custom-control-label" for="switchJury"
                    >Jury</label
                  >
                </div>
                <div class="custom-control custom-switch">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="switchPartner"
                    v-model="roles.partner"
                    :disabled="contest_is_active === false"
                  />
                  <label class="custom-control-label" for="switchPartner"
                    >Partner</label
                  >
                </div>
                <div class="custom-control custom-switch">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="switchAnalytics"
                    v-model="roles.analytics"
                    :disabled="contest_is_active === false"
                  />
                  <label class="custom-control-label" for="switchAnalytics"
                    >Analytics</label
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- INFO -->
        <div class="row form-group">
          <!-- Current editions -->
          <aside class="col-12 col-lg-4 col-xl-3 offset-xl-1">
            <h2>Current edition</h2>
          </aside>
          <div class="col-12 col-lg-8 offset-lg-4 col-xl-7 offset-xl-0 mb-4">
            <!-- Edition info -->
            <div class="mb-5">
              <div class="border p-3 mb-4">
                <div class="mb-3">
                  <label>Title</label>
                  <input
                    class="form-control"
                    type="text"
                    name="surname"
                    v-model="editions.current_edition.title"
                    required
                  />
                </div>
                <div class="mb-3">
                  <label>Description</label>
                  <input
                    class="form-control"
                    type="text"
                    name="surname"
                    v-model="editions.current_edition.description"
                    required
                  />
                </div>
                <div class="mb-3">
                  <label>Year (Contest start year, es: 2021)</label>
                  <input
                    class="form-control"
                    type="text"
                    name="surname"
                    v-model="editions.current_edition.year"
                    required
                  />
                </div>
              </div>
            </div>

            <!-- Edition -->
            <div class="mb-5">
              <div class="border p-3 mb-4">
                <div class="mb-3">
                  <label>Number of finalists posters</label>
                  <input
                    class="form-control"
                    type="number"
                    name="numFinalists"
                    v-model="editions.current_edition.num_finalists"
                    required
                    :disabled="contest_is_active === false"
                  />
                </div>
                <div class="mb-3">
                  <label>Number of winners posters</label>
                  <input
                    class="form-control"
                    type="number"
                    name="numWinners"
                    v-model="editions.current_edition.num_winners"
                    required
                    :disabled="contest_is_active === false"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- OLD EDITIONS -->
        <div class="row form-group">
          <!-- Old editions -->
          <aside class="col-12 col-lg-4 col-xl-3 offset-xl-1">
            <h2>OLD editions</h2>
          </aside>
          <div class="col-12 col-lg-8 offset-lg-4 col-xl-7 offset-xl-0 mb-4">
            <!-- Edition info -->
            <div
              class="mb-5"
              v-for="edition in editions.past_editions"
              v-bind:key="edition.edition_ID"
            >
              <div class="mb-5">
                <div class="border p-3 mb-4">
                  <div class="">
                    <label class="mr-1">ID:</label>
                    <p class="d-inline-block">{{ edition.edition_ID }}</p>
                  </div>
                  <div class="">
                    <label class="mr-1">Year:</label>
                    <p class="d-inline-block">{{ edition.year }}</p>
                  </div>
                  <div class="">
                    <label class="mr-1">Title:</label>
                    <p class="d-inline-block">{{ edition.title }}</p>
                  </div>
                  <div class="">
                    <label class="mr-1">Description:</label>
                    <p class="d-inline-block">{{ edition.description }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Submit + Status -->
        <div class="row form-group">
          <aside class="col-12 col-lg-4 col-xl-3 offset-xl-1"></aside>

          <!-- Submit -->
          <div class="col-12 col-lg-8 offset-lg-4 col-xl-7 offset-xl-0">
            <!-- Button -->
            <div class="mb-5">
              <input
                type="submit"
                class="btn"
                :class="submitStatus == 1 ? 'btn-primary' : 'btn-secondary'"
                :value="submitStatus == 1 ? 'Save' : 'Saving'"
                :disabled="submitStatus == 1 ? false : true"
              />
            </div>
            <!-- Status -->
            <div
              class="mb-5 bg-secondary text-white p-3 border mb-3 rounded"
              v-html="status"
              v-if="status"
            ></div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import firebase from "firebase";

export default {
  name: "Settings",
  components: {},
  data() {
    return {
      edition_ID_without_ph: "",
      status: "",
      submitStatus: 1,
      contestStatus: "",
    };
  },
  computed: {
    contest_is_active() {
      return this.$store.state.options.contest_active; // contest is active?
    },
    roles() {
      return this.$store.state.options.roles_active; // get roles from store
    },
    editions() {
      return this.$store.state.options.contest_editions; // get editions from store
    },
    // edition_ID_without_ph() {
    //   let sub = this.$store.state.options.contest_editions.current_edition.edition_ID;
    //   let value = sub.substring(3);
    //   return value;
    // }
  },
  // beforeCreate() {
  //   this.remove_ph_from_edition_ID();
  // },
  created: function () {
    this.remove_ph_from_edition_ID();
  },
  methods: {
    remove_ph_from_edition_ID() {
      let sub = this.editions.current_edition.edition_ID;
      let value = sub.substring(3);
      this.edition_ID_without_ph = value;
    },

    startNewEdition() {
      const self = this;

      // Controllo se l'id inserito per la nuova edizione è univoco, quindi non già utilizzato in passato
      let new_edition_ID = "ph_" + self.edition_ID_without_ph;
      console.log("NEW: ", new_edition_ID);

      let array_editions_id = [];
      for (const edition in self.editions.past_editions) {
        console.log("current: ", edition);
        array_editions_id.push(edition);
      }

      console.log("array: ", array_editions_id);

      let new_id_is_already_in_use = array_editions_id.includes(new_edition_ID);
      console.log("is already: ", new_id_is_already_in_use);

      if (new_id_is_already_in_use) {
        alert(
          "L'ID che è stato inserito non va bene, è già stato utilizzato in passato"
        );
      } else {
        alert(
          "L'ID che è stato inserito verrà utilizzato per la nuova edizione"
        );

        var closed = prompt(
          "Stai per avviare una nuova edizione del contest. Questa operazione creerà una nuova cartella per i poster che verranno caricati dagli utenti. Se sei intenzionato a procedere scrivi: 'OPEN'",
          ""
        );
        if (closed === "OPEN") {
          alert(
            "Hai aperto il concorso, verrà creata una cartella per i nuovi upload."
          );
          // this.contest_is_active = true;
          this.saveContestStatus(true);
          this.saveStorageFolderName();
        } else {
          alert("Il contest non è stato aperto.");
        }
      }
    },

    archiveOldEdition() {
      const self = this;

      var closed = prompt(
        "Stai per chiudere l'edizione attualmente attiva. Tra le vecchie edizioni verranno salvate le seguenti informazioni, Title: " +
          self.editions.current_edition.title +
          ", Description: " +
          self.editions.current_edition.description +
          ", Year: " +
          self.editions.current_edition.year +
          ". Questa operazione cancellerà tutti i poster che non sono stati selezionati come finalisti. Se sei intenzionato a procedere scrivi: 'CLOSED'",
        ""
      );
      if (closed === "CLOSED") {
        alert("Hai chiuso il concorso, i poster verranno cancellati.");
        self.saveEditionInThePast();
        console.log(
          'QUI BISOGNA ELIMINARE TUTTI I FILE NELLO STORAGE CHE NON SONO PRESENTI NELLA TABELLA "WINNERS"'
        );
        self.saveContestStatus(false);
      } else {
        alert("Il contest non è stato chiuso.");
      }
    },

    saveEditionInThePast() {
      const self = this;

      let past_edition = {
        // edition_ID: 'ph_'+this.edition_ID_without_ph,
        edition_ID: this.editions.current_edition.edition_ID,
        title: this.editions.current_edition.title,
        description: this.editions.current_edition.description,
        year: this.editions.current_edition.year,
      };

      // let id = this.editions.current_edition.edition_ID;

      firebase
        .firestore()
        .collection("options")
        .doc("general")
        .set(
          {
            contest_editions: {
              // current_edition: {
              //   // edition_ID: 'ph_'+this.editions.current_edition.edition_ID,
              //   edition_ID: 'ph_'+this.edition_ID_without_ph,
              //   num_finalists: this.editions.current_edition.num_finalists,
              //   num_winners: this.editions.current_edition.num_winners,
              //   title: this.editions.current_edition.title,
              //   description: this.editions.current_edition.description,
              //   year: this.editions.current_edition.year,
              // },
              past_editions: {
                [this.editions.current_edition.edition_ID]: past_edition,
              },
            },
          },
          { merge: true }
        )
        .then(() => {
          self.contestStatus = "Success";
          // Reaload page for reset the store with database data
          window.location.reload();
        })
        .catch(function (error) {
          self.contestStatus = error;
        });
    },

    saveContestStatus(status) {
      const self = this;
      self.submitStatus = 0;
      self.status = "Saving data into database";

      firebase
        .firestore()
        .collection("options")
        .doc("general")
        .update({
          contest_active: status,
          roles_active: {
            admin: true,
            user_can_login: false,
            user_can_register: false,
            user_can_upload: false,
            selector: false,
            jury: false,
            analytics: false,
            partner: false,
          },
        })
        .then(() => {
          self.contestStatus = "Success";
          // Reaload page for reset the store with database data
          window.location.reload();
        })
        .catch(function (error) {
          self.contestStatus = error;
        });
    },

    saveStorageFolderName() {
      const self = this;
      self.submitStatus = 0;
      self.status = "Saving data into database";

      firebase
        .firestore()
        .collection("options")
        .doc("general")
        .set(
          {
            contest_editions: {
              current_edition: {
                // edition_ID: 'ph_'+this.editions.current_edition.edition_ID,
                edition_ID: "ph_" + this.edition_ID_without_ph,
                num_finalists: this.editions.current_edition.num_finalists,
                num_winners: this.editions.current_edition.num_winners,
                title: this.editions.current_edition.title,
                description: this.editions.current_edition.description,
                year: this.editions.current_edition.year,
              },
              // past_editions: {}
            },
          },
          { merge: true }
        )
        .then(() => {
          self.contestStatus = "Success";
          // Reaload page for reset the store with database data
          window.location.reload();
        })
        .catch(function (error) {
          self.contestStatus = error;
        });
    },

    // Submit
    saveSettings() {
      const self = this;
      self.submitStatus = 0;
      self.status = "Saving data into database";

      // Create roles object
      // let new_roles;
      // if (this.contest_is_active) {
      //   new_roles = this.roles
      // } else {
      //   new_roles = {
      //     admin: true,
      //     user_can_register: false,
      //     user_can_upload: false,
      //     selector: false,
      //     jury: false,
      //     partner: false,
      //     analytics: false,
      //   };
      // }

      firebase
        .firestore()
        .collection("options")
        .doc("general")
        .update({
          // contest_active: this.contest_is_active,
          roles_active: this.roles,
          contest_editions: this.editions,
        })
        .then(() => {
          self.status = "Success";
          self.submitStatus = 1;
        })
        .catch(function (error) {
          self.status = error;
          self.submitStatus = 1;
        });
    },
  },
};
</script>


<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped>
</style>
