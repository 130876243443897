<template>
  <div id="anagraphical">
    <div class="row">
      <div
        class="col-12 col-lg-8 offset-lg-2 d-flex flex-column justify-content-center"
      >
        <!-- Login info -->
        <div class="row">
          <div class="col-12">
            <div class="">
              <label class="text-uppercase mb-0">Email</label>
              <input
                class="form-control mb-4"
                type="email"
                name="email"
                v-model="userInfo.email"
                required
                :disabled="fieldsAreDisabled === true"
              />
            </div>
          </div>
        </div>

        <!-- Personal Info -->
        <div class="row mb-5">
          <div class="col-12">
            <form class="page-content" @submit.prevent="doUpdate">
              <div class="row mb-5">
                <div class="col-12">
                  <div class="">
                    <label class="text-uppercase mb-0">Name</label>
                    <input
                      class="form-control mb-4"
                      type="text"
                      name="name"
                      v-model="userInfo.name"
                      required
                      :disabled="fieldsAreDisabled === true"
                    />
                  </div>
                  <div class="">
                    <label class="text-uppercase mb-0">Surname</label>
                    <input
                      class="form-control mb-4"
                      type="text"
                      name="surname"
                      v-model="userInfo.surname"
                      required
                      :disabled="fieldsAreDisabled === true"
                    />
                  </div>
                  <!-- <div class="">
                    <label class="text-uppercase mb-0">Date of Birth</label>
                    <date-picker
                      class="w-100 mb-4"
                      v-model="userInfo.dateOfBirth"
                      valueType="DD/MM/YYYY"
                      :disabled="fieldsAreDisabled === true"
                    ></date-picker>
                  </div> -->
                  <div class="">
                    <!-- <label class="d-block">* Date of Birth</label> -->
                    <ValidationProvider
                      name="date"
                      rules="date"
                      v-slot="{ errors }"
                    >
                      <input
                        class="custom-select mb-4"
                        type="text"
                        name="date"
                        placeholder="Date of Birth (dd/mm/yyyy) *"
                        v-model="userInfo.dateOfBirth"
                        :disabled="fieldsAreDisabled === true"
                        required
                      />
                      <span class="d-block small mb-4" >{{
                        errors[0]
                      }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="">
                    <label class="text-uppercase mb-0">Gender</label>
                    <select
                      class="custom-select mb-4"
                      name="gender"
                      v-model="userInfo.gender"
                      required
                      :disabled="fieldsAreDisabled === true"
                    >
                      <option value="Female">Female</option>
                      <option value="Male">Male</option>
                      <option value="Other">Other</option>
                    </select>
                  </div>
                  <div class="">
                    <label class="text-uppercase mb-0">Phone</label>
                    <!-- <template class="d-block"> -->
                    <!-- <vue-tel-input
                      class="mb-4"
                      v-model="userInfo.phone"
                      :disabled="fieldsAreDisabled === true"
                    ></vue-tel-input> -->
                    <!-- </template> -->
                    <VuePhoneNumberInput
                      class="mb-5"
                      placeholder="Phone *"
                      v-model="userInfo.phone"
                      :disabled="fieldsAreDisabled === true"
                      required
                    />
                  </div>
                  <div class="">
                    <label class="text-uppercase mb-0">Nationality</label>
                    <select
                      class="custom-select mb-4"
                      name="nationality"
                      v-model="userInfo.nationality"
                      required
                      :disabled="fieldsAreDisabled === true"
                    >
                      <option
                        v-for="country in countries"
                        :value="country.label"
                        :key="country.value"
                      >
                        {{ country.label }}
                      </option>
                    </select>
                  </div>
                  <div class="">
                    <label class="text-uppercase mb-0">Working position</label>
                    <select
                      class="custom-select mb-4"
                      name="workingPosition"
                      v-model="userInfo.workingPosition"
                      required
                      :disabled="fieldsAreDisabled === true"
                    >
                      <option value="Student">Student</option>
                      <option value="Worker">Worker</option>
                      <option value="Other">Other</option>
                    </select>
                    <br />
                  </div>

                  <div class="mb-3 mt-4">
                    <input
                      type="checkbox"
                      v-model="userInfo.representative"
                      name="representative"
                      :checked="userInfo.representative == true"
                    />
                    <span class="text-uppercase">
                      I am a representative of a group
                    </span>
                  </div>
                  <div class="mb-3" v-if="userInfo.representative">
                    <input
                      class="form-control mb-3"
                      type="text"
                      name="group"
                      v-model="userInfo.groupName"
                      placeholder="Group name (or the agency)"
                      :disabled="fieldsAreDisabled === true"
                    />
                    <input
                      class="form-control mb-3"
                      type="text"
                      name="groupMembers"
                      v-model="userInfo.groupMembers"
                      placeholder="Name of the group members (separated by comma)"
                      :disabled="fieldsAreDisabled === true"
                    />
                  </div>

                  <div class="pt-4 mt-4 border-top">
                    <div>
                      <p class="font-size-2rem text-uppercase">
                        SHIPPING DETAILS
                      </p>
                    </div>
                    <div class="mb-3">
                      <label class="text-uppercase">Address</label>
                      <input
                        class="form-control"
                        type="text"
                        name="address"
                        v-model="userInfo.address"
                        placeholder="ex.: Via del Corso 16"
                        required
                        :disabled="fieldsAreDisabled === true"
                      />
                    </div>
                    <div class="mb-3">
                      <label class="text-uppercase">ZIP Code</label>
                      <input
                        class="form-control"
                        type="text"
                        name="zipCode"
                        v-model="userInfo.zipCode"
                        placeholder="ex.: 10000"
                        required
                        :disabled="fieldsAreDisabled === true"
                      />
                    </div>
                    <div class="mb-3">
                      <label class="text-uppercase">City</label>
                      <input
                        class="form-control"
                        type="text"
                        name="city"
                        v-model="userInfo.city"
                        placeholder="ex.: Rome"
                        required
                        :disabled="fieldsAreDisabled === true"
                      />
                    </div>
                    <div class="pb-4 mb-4">
                      <label class="text-uppercase">Country</label>
                      <!-- <select
                    class="custom-select"
                    name="country"
                    v-model="userInfo.country"
                    required
                    :disabled="fieldsAreDisabled === true"
                  >
                    <option v-for="country in countries" :key="country.id">
                      {{ country.name }}
                    </option>
                  </select> -->
                      <select
                        class="custom-select"
                        name="country"
                        v-model="userInfo.country"
                        required
                        :disabled="fieldsAreDisabled === true"
                      >
                        <option
                          v-for="country in countries"
                          :value="country.label"
                          :key="country.value"
                        >
                          {{ country.label }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Submit + Status -->
              <div class="form-group row">

                <!-- Submit -->
                <!-- <div class="col-12 col-lg-8 offset-lg-4 col-xl-7 offset-xl-0"> -->
                <div class="col-12">
                  <!-- Button -->
                  <div class="d-flex justify-content-center mb-5" v-if="fieldsAreDisabled">
                    <input
                      type="button"
                      class="btn btn-primary w-1000 font-size-1rem"
                      value="click for change your anagraphical"
                      @click="enableFields()"
                    />
                  </div>
                  <div class="d-flex justify-content-center mb-5" v-else>
                    <input
                      type="submit"
                      class="btn"
                      :class="
                        submitStatus == 1 ? 'btn-primary w-50' : 'btn-secondary'
                      "
                      :value="submitStatus == 1 ? 'Save' : 'Saving'"
                      :disabled="submitStatus == 1 ? false : true"
                    />
                  </div>
                </div>
                <!-- Status -->
                <div class="col-12 mb-5">
                <div
                  class="btn btn-secondary"
                  v-html="status"
                  v-if="status"
                ></div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase";
// import RegisterClosed from "@/components/Auth/RegisterClosed";

// DATE PICKER
// import DatePicker from "vue2-datepicker";
// import "vue2-datepicker/index.css";
import moment from "moment";

// PHONE - https://github.com/LouisMazel/vue-phone-number-input
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";

// COUNTRY
// https://www.michael1e.com/how-to-create-a-country-select-dropdown-in-vue/
const countries = require("i18n-iso-countries");
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

// VeeValidate
import { ValidationProvider } from "vee-validate/dist/vee-validate.full.esm";
import { extend } from "vee-validate";
// import * as rules from "vee-validate/dist/rules";
// import { messages } from "vee-validate/dist/locale/en.json";
extend("date", {
  validate: (value) =>
    /^(19|20)\d\d[/]([1-9]|0[1-9]|1[012])[/]([1-9]|0[1-9]|[12][0-9]|3[01])$/.test(
      value
    ),
  message: "Date must be in YYYY/MM/DD format",
});

export default {
  name: "Anagraphical",
  components: {
    // DatePicker,
    // VueTelInput,
    // RegisterClosed,
    VuePhoneNumberInput,
    ValidationProvider,
  },
  data() {
    return {
      fieldsAreDisabled: true,
      auth: {
        email: "",
        // password: "",
      },
      user: {
        email: "",
        name: "",
        surname: "",
        dateOfBirth: "",
        gender: "",
        phone: "",
        nationality: "",
        workingPosition: "",
        address: "",
        zipCode: "",
        city: "",
        country: "",
        representative: false,
        groupName: "",
        groupMembers: "",
      },
      status: "",
      submitStatus: 1,
      // countries: [],
      phoneProps: {
        mode: "international",
        defaultCountry: "IT",
        disabledFetchingCountry: false,
        disabled: false,
        disabledFormatting: false,
        placeholder: "Enter a phone number",
        required: false,
        enabledCountryCode: false,
        enabledFlags: true,
        preferredCountries: [],
        onlyCountries: [],
        ignoredCountries: [],
        autocomplete: "off",
        name: "telephone",
        maxLen: 20,
        wrapperClasses: "",
        inputClasses: "form-control",
        dropdownOptions: {
          disabledDialCode: false,
        },
        inputOptions: {
          showDialCode: false,
        },
      },
    };
  },
  computed: {
    userInfo() {
      return this.$store.state.user; // get user from store
    },
    countries() {
      const list = countries.getNames("en", { select: "official" });
      return Object.keys(list).map((key) => ({ value: key, label: list[key] }));
    },
    momentDateOfBirth: {
      get: function () {
        return this.user.dateOfBirth;
        // return this.user.dateOfBirth.moment().format("MMMM, Do YYYY");
      },
      set: function (value) {
        this.user.dateOfBirth = moment(value).format("YYYY-MM-DD");
      },
    },
  },
  created: function () {
    const currentUser = firebase.auth().currentUser;

    firebase.auth().onAuthStateChanged((currentUser) => {
      if (currentUser) {
        let email = currentUser.email;
        this.auth.email = email;
      } else {
        console.log("error");
      }
    });

    // this.getCountriesList();
  },

  methods: {
    // getCountriesList: function () {
    //   this.$http
    //     // .get("https://restcountries.eu/rest/v1/all")
    //     .get("https://restcountries.com/v2/all")
    //     .then((response) => {
    //       this.countries = response.body;
    //     });
    // },
    enableFields() {
      this.fieldsAreDisabled = false;
    },
    doUpdate() {
      const self = this;
      self.submitStatus = 0;
      // self.status = "Creating the account...";

      // Date Update
      let dateUpdate = new Date().toLocaleString();

      // Create user object
      const user = {
        // email: this.auth.email,
        email: this.userInfo.email,
        name: this.userInfo.name,
        surname: this.userInfo.surname,
        dateOfBirth: this.userInfo.dateOfBirth,
        gender: this.userInfo.gender,
        phone: this.userInfo.phone,
        nationality: this.userInfo.nationality,
        workingPosition: this.userInfo.workingPosition,
        address: this.userInfo.address,
        zipCode: this.userInfo.zipCode,
        city: this.userInfo.city,
        country: this.userInfo.country,
        representative: this.userInfo.representative,
        groupName: this.userInfo.groupName,
        groupMembers: this.userInfo.groupMembers,
        role: "user",
        dateCreate: this.userInfo.dateCreate,
        dateUpdate: dateUpdate,
        dateUpload: this.userInfo.dateUpload,
      };

      // firebase
      //   .firestore()
      //   .collection("users")
      //   .doc()
      //   .where("uID", "==", this.userInfo.uID)
      //   .update(user)
      //   .then(() => {
      //     self.status = "Success";
      //     self.submitStatus = 1;
      //     this.fieldsAreDisabled = true;
      //   })
      //   .catch(function (error) {
      //     self.status = error;
      //     self.submitStatus = 1;
      //   });

      const currentUser = firebase.auth().currentUser;
      // const newEmail = this.auth.email;
      const newEmail = this.userInfo.email;

      currentUser
        .updateEmail(newEmail)
        .then(() => {
          // console.log("Success - New password: " + newEmail);

          firebase
            .firestore()
            .collection("users")
            .where("uID", "==", this.userInfo.uID)
            .get()
            .then((querySnapshot) => {
              querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                // console.log(doc.id, " => ", doc.data());
                firebase
                  .firestore()
                  .collection("users")
                  .doc(doc.id)
                  .update(user)
                  .then(() => {
                    self.status = "Success";
                    self.submitStatus = 1;
                    this.fieldsAreDisabled = true;
                  })
                  .catch(function (error) {
                    self.status = error;
                    self.submitStatus = 1;
                  });
              });
            })
            .catch((error) => {
              console.log("Error: ", error);
            });
        })
        .catch((error) => {
          console.log("Error: ", error);
        });
    },
  },
};
</script>



<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped>
.vue-tel-input {
  border: 1px solid #ced4da;
  border-radius: 0px;
  height: calc(1.5em + 0.75rem + 2px);
}
.vue-tel-input:focus {
  outline: none;
}
.vue-tel-input:focus-within {
  /* box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6); */
  box-shadow: none;
  border-color: #ced4da;
}
input[type="tel"] {
  border-radius: 0px;
}
.vti__dropdown.open {
  outline: none !important;
}
.vti__dropdown:focus {
  outline: none !important;
}
.open:focus {
  outline: none !important;
}
</style>
