<template>
  <div id="analytics">
    <div v-if="analytics_is_active" class="row">
      <div class="col-12">

        <!-- Header -->
        <div class="page-title row mt-5 pt-5">
          <div class="col-12">
            <h1 class>Analytics</h1>
          </div>
        </div>

        <div class="filters row justify-content-between border-bottom mb-5">
          <div class="col-auto text-left">
            Show
            <a
              :class="{ active: category === 'users' }"
              v-on:click="category = 'users'"
              >Users</a
            >
            |
            <a
              :class="{ active: category === 'submissions' }"
              v-on:click="category = 'submissions'"
              >Submissions</a
            >
            |
            <a
              :class="{ active: category === 'ratings' }"
              v-on:click="category = 'ratings'"
              >Ratings</a
            >
          </div>

          <!-- Download PDF -->
          <div class="col auto text-right">
            <p v-if="category === 'users'">
              <a class="btn border">
                <download-excel
                  :data="users"
                  :fields="users_fields"
                  name="users.xls"
                  >Download Users</download-excel
                >
              </a>
            </p>
            <p v-else-if="category === 'submissions'">
              <a class="btn border">
                <download-excel
                  :data="submissionsDownload"
                  :fields="submissions_fields"
                  name="submissions.xls"
                  >Download Submissions</download-excel
                >
              </a>
            </p>
            <p v-else-if="category === 'ratings'">
              <a class="btn border">
                <download-excel
                  :data="ratesPosters"
                  :fields="rates_fields"
                  name="ratings.xls"
                  >Download Ratings</download-excel
                >
              </a>
            </p>
          </div>
        </div>

        <div class="col-12">
          <!-- <components v-bind:is="component" v-bind="setProperties" /> -->
          <!-- <components v-bind:is="component" /> -->
          <components v-bind:is="component" v-bind="setProperties" />
        </div>

        <!-- Logout -->
        <div class="row border-top pt-3 mt-5">
          <div class="col-12">
            <Logout />
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <ThankYou />
    </div>
  </div>
</template>

<script>
import Analyticsusers from "@/components/Content/Analytics/AnalyticsUsers";
import Analyticssubmissions from "@/components/Content/Analytics/AnalyticsSubmissions";
import Analyticsratings from "@/components/Content/Analytics/AnalyticsRatings";

import firebase from "firebase";
import Logout from "@/components/Auth/Logout";
import ThankYou from "@/components/ThankYou";

export default {
  name: "Analytics",
  components: {
    Logout,
    Analyticsusers,
    Analyticssubmissions,
    Analyticsratings,
    ThankYou,
  },
  data() {
    return {
      usersID: [],
      users: [],
      usersDownload: [],
      submissions: [],
      submissionsDownload: [],
      nationality: [],
      rates: [],
      ratesPosters: [],
      jury: [],
      juryRates: [],
      analisiF: false,
      category: "users",
      component: "Analyticsusers",
      users_fields: {
        Country: "country",
        Address: "address",
        "Working Position": "workingPosition",
        "Date Upload": "dateUpload",
        "Date Update": "dateUpdate",
        "Date Create": "dateCreate",
        groupName: "groupName",
        groupMembers: "groupMembers",
        role: "role",
        zipCode: "zipCode",
        phone: "phone",
        gender: "gender",
        city: "city",
        nationality: "nationality",
        name: "name",
        surname: "surname",
        dateOfBirth: "dateOfBirth",
        "User ID": "uID",
        "User email": "email",
      },
      submissions_fields: {
        Title: "title",
        Type: "type",
        Content: "content",
        Path: "path",
        Name: "name",
        Link: "link",
        Status: "status",
        Size: "size",
      },
      rates_fields: {
        "User ID": "uID",
        "User Name": "uName",
        "User Surname": "uSurname",
        Title: "title",
        Number: "number",
        Size: "size",
        Type: "type",
        Content: "content",
        Name: "name",
        Status: "status",
        Rate: "rate.total",
        Path: "path",
        Link: "link_big",
      },
    };
  },
  computed: {
    analytics_is_active() {
      return this.$store.state.options.roles_active.analytics; // analytics is active?
    },
    id_current_edition() {
      return this.$store.getters.getIDCurrentEdition; // get id current edition from store
    },
    editions() {
      return this.$store.state.options.contest_editions; // get editions from store
    },
    // setProperties: function() {
    //   // if (this.component === 'Analyticsusers') {
    //     // return { users: this.users, nationality: this.nationality }
    //   // }
    //   // if (this.component === 'Analyticssubmissions') {
    //   //   return { submissions: this.submissions }
    //   // }
    //   // if (this.component === 'Analyticsratings') {
    //   //   return { ratesPosters: this.ratesPosters, jury: this.jury }
    //   // }
    // }
    setProperties: function () {
      return {
        users: this.users,
        nationality: this.nationality,
        submissions: this.submissions,
        jury: this.jury,
        ratesPosters: this.ratesPosters,
      };
    },
  },
  watch: {
    category() {
      this.component = "Analytics" + this.category;
    },
  },
  created() {
    this.getDb();
  },
  methods: {
    downloadExcel(collection) {},

    getDb() {
      const self = this;

      // ANALYTICS USERS: create array users
      // firebase
      //   .firestore()
      //   .collection("users")
      //   .where("role", "==", "user")
      //   .get()
      //   .then((querySnapshot) => {
      //     querySnapshot.forEach((user) => {
      //       self.users.push(user.data());
      //     });
      //   })
      //   .then(() => {
      //     this.createNation();
      //   });

      const condition_edition =
        "editions." + self.id_current_edition + ".info.edition_ID";
      firebase
        .firestore()
        .collection("submissions")
        .where(
          condition_edition,
          "==",
          self.editions.current_edition.edition_ID
        )
        .get()
        .then((querySnapshot) => {
          console.log("query: ", querySnapshot);
          querySnapshot.forEach((sub) => {
            self.usersID.push(sub.data().uID);
          });
          // console.log("Users ID: : ", self.usersID);
          firebase
            .firestore()
            .collection("users")
            .where("role", "==", "user")
            .get()
            .then((querySnapshot) => {
              querySnapshot.forEach((user) => {
                const id = user.data().uID;
                console.log("id: ", id);
                if (self.usersID.includes(id)) {
                  console.log("Name: ", user.data().name);
                  self.users.push(user.data());
                }
              });
            })
            .then(() => {
              this.createNation();
            });
        })
        .catch((error) => {
          console.error("Error: ", error);
        });

      // ANALYTICS SUBMISSIONS: create array submissions
      // // console.log('id current edition: ',self.id_current_edition);
      // // console.log('current edition: ',self.editions.current_edition.edition_ID);
      const condition =
        "editions." + self.id_current_edition + ".info.edition_ID";
      firebase
        .firestore()
        .collection("submissions")
        // .where("year", "==", this.$store.state.options.year)
        .where(condition, "==", self.editions.current_edition.edition_ID)
        .get()
        .then((querySnapshot) => {
          // // console.log('query: ',querySnapshot);
          querySnapshot.forEach((sub) => {
            // this.submissions.push(sub.data());

            // const createdAt = (sub.data().created_at === undefined) ? "" : sub.data().created_at;
            // const posters = sub.data().posters;
            // // console.log('POSTERS: ',posters);

            // posters.forEach(poster => {
            //   poster["uid"] = sub.data().uID;
            //   poster["type"] = "poster";
            //   poster["created_at"] = createdAt;
            //   self.submissionsDownload.push(poster);
            // });

            sub
              .data()
              .editions[this.id_current_edition].posters.forEach(
                (submission) => {
                  // // console.log('submission: ',submission);
                  self.submissions.push(submission);
                  self.submissionsDownload.push(submission);
                }
              );
          });
        })
        .catch((error) => {
          console.error("Error: ", error);
        });

      // ANALYTICS RATINGS: create array jury
      firebase
        .firestore()
        .collection("users")
        .where("role", "==", "jury")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((jury) => {
            self.jury.push(jury.data());
          });
        });

      // ANALYTICS RATINGS: create array rates
      firebase
        .firestore()
        .collection("ratings")
        // .where("year", "==", this.$store.state.options.year)
        .where("edition_ID", "==", self.editions.current_edition.edition_ID)
        // .where("submitted", '==', true)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((rate) => {
            // // console.log('Rate: ',rate.data());
            self.rates.push(rate.data());
          });
          // console.log('rates: ',self.rates);
        })
        .then(() => {
          this.createRates();
          this.clearRates();
          this.joinJury();
        });
    },

    // ANALYTICS USERS: create array nationality
    createNation: function () {
      for (var i = 0; i < this.users.length; i++) {
        const _nation = this.users[i].nationality;

        let filled = false;

        for (var j = 0; j < this.nationality.length; j++) {
          if (this.nationality[j].name === _nation) {
            this.nationality[j].value += 1;
            filled = true;
          }
        }
        if (!filled) {
          // means that Nation does not exist
          this.nationality.push({ name: _nation, value: 1, perc: "" });
        }
      }
      // let len = this.nationality.length;
      for (var n = 0; n < this.nationality.length; n++) {
        this.nationality[n].perc =
          ((this.nationality[n].value / this.users.length) * 100).toFixed(2) +
          "%";
      }
    },

    // ANALYTICS RATINGS: create array ratesPosters
    createRates: function () {
      this.ratesPosters = [];

      // i = numero di persone che votano
      for (var i = 0; i < this.rates.length; i++) {
        this.juryRates.push({
          uID: this.rates[i].uID_jury,
          value: this.rates[i].submitted,
        });

        // j = per il numero di posters
        for (var j = 0; j < this.rates[i].posters.length; j++) {
          if (this.rates[i].posters[j] === "") {
            this.rates[i].posters[j].rate = 0;
          }
          const temp = { ...this.rates[i].posters[j] };
          this.ratesPosters.push(temp);
        }
      }
    },
    // ANALYTICS RATINGS: clear array ratesPosters
    clearRates: function () {
      let filledPoster = false;
      const ratePosters = [];

      // i = per il numero di posters
      for (var i = 0; i < this.ratesPosters.length; i++) {
        const _path = this.ratesPosters[i].path;
        // p = per il numero
        for (var p = 0; p < ratePosters.length; p++) {
          if (ratePosters[p].path === _path) {
            // ratePosters[p].rate = Number(ratePosters[p].rate) + Number(this.ratesPosters[i].rate);
            // Aesthetics
            ratePosters[p].rate.aesthetics =
              Number(ratePosters[p].rate.aesthetics) +
              Number(this.ratesPosters[i].rate.aesthetics);
            // Theme
            ratePosters[p].rate.theme =
              Number(ratePosters[p].rate.theme) +
              Number(this.ratesPosters[i].rate.theme);
            // Originality
            ratePosters[p].rate.originality =
              Number(ratePosters[p].rate.originality) +
              Number(this.ratesPosters[i].rate.originality);
            // Total
            ratePosters[p].rate.total =
              Number(ratePosters[p].rate.total) +
              Number(this.ratesPosters[i].rate.total);
            filledPoster = true;
            break;
          }
        }
        if (!filledPoster) {
          // means that the rated post does not exist
          // // console.log('rates: ',this.ratesPosters[i]);
          const temp = { ...this.ratesPosters[i] };
          // // console.log('temp: ',temp);
          ratePosters.push(temp);
        }
      }
      // // console.log('ratePosters: ',ratePosters)
      this.ratesPosters = ratePosters;
      // console.log('Rates Posters: ',this.ratesPosters);

      // Aggiungo nome e cognome dell'utente all'array dei posters
      // console.log('Rates PRIMA: ',this.ratesPosters);
      this.ratesPosters.forEach((poster) => {
        const user_id = poster.uID;

        let _poster = poster;
        _poster.uName = "";
        _poster.uSurname = "";

        firebase
          .firestore()
          .collection("users")
          .where("uID", "==", user_id)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              const uName = doc.data().name;
              const uSurname = doc.data().surname;
              _poster.uName = uName;
              _poster.uSurname = uSurname;
            });
          })
          .catch(function (error) {
            console.log(error);
          });
      });
      // console.log('Rates DOPO: ',this.ratesPosters);
    },
    // ANALYTICS RATINGS: join jury
    joinJury: function () {
      for (var j = 0; j < this.jury.length; j++) {
        let filled = false;
        for (var r = 0; r < this.juryRates.length; r++) {
          // confronto uID
          if (this.jury[j]["uID"] === this.juryRates[r]["uID"]) {
            this.jury[j]["value"] = this.juryRates[r].value;
            filled = true;
            break;
          }
        }
        if (!filled) {
          // means that the jury did not rate yet
          this.jury[j]["value"] = "never";
        }
      }
    },
  },
};
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped>
a:hover {
  font-weight: bold;
}

.filters a.active {
  font-weight: 700;
}
</style>
