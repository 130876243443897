<template>
  <div id="analyticsRatings">
    <div class="row mb-5">
      <div class="col-12">

        <!-- <pre>
          JURY
          {{jury}}
        </pre> -->
        <!-- <pre>
          RATES POSTER
          {{ratesPosters}}
        </pre> -->

        <h3>Jury</h3>
        <span v-for="judge in jury" :key="judge.name">
          <p v-if="judge.value === true">[{{ judge.uID }}] {{ judge.name }} {{ judge.surname }} rated.</p>
          <p v-else style="color: orange">
            <span v-if="judge.value === false">[{{ judge.uID }}] {{ judge.name }} {{ judge.surname }} did NOT FINISH to rate.</span>
            <span v-else style="color: red">[{{ judge.uID }}] {{ judge.name }} {{ judge.surname }} NEVER STARTED to rate.</span>
          </p>
        </span>
      </div>
    </div>
    <div class="row mb-5">
      <div class="col">
        <h3>Posters</h3>
        <table id="poster" class="table table-striped">
          <thead>
            <tr>
              <th id="rateP" class="sortableP desc" v-on:click="sortByP('rate')">Rate</th>
              <th>Creator</th>
              <th>Name</th>
              <th>Surname</th>
              <th>Path</th>
            </tr>
          </thead>
          <!-- <pre>{{currentPosters.length}}</pre> -->
          <!-- <pre>{{currentPosters}}</pre> -->
          <tbody>
            <tr v-for="poster in currentPosters" :key="poster.id">
              <!-- <td><pre>{{poster}}</pre></td> -->
              <td>{{ poster.rate.total }}</td>
              <td>{{ poster.uID }}</td>
              <td>{{ poster.uName }}</td>
              <td>{{ poster.uSurname }}</td>
              <td>{{ poster.path }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- pagination Posters -->
    <div class="row mb-5">
      <div class="col text-left">
        <button
          class="pag btn border"
          :class="currentPage['p'] === 0 ? 'd-none' : 'd-inline'"
          :disabled="currentPage['p'] === 0"
          @click="prevPageP"
        >&laquo;</button>
      </div>
      <div class="col text-center">Pagina {{ currentPage['p'] + 1 }} di {{ lastPageP + 1 }}</div>
      <div class="col text-right">
        <button
          class="pag btn border"
          :class="currentPage['p'] === lastPageP ? 'd-none' : 'd-inline'"
          :disabled="currentPage['p'] === lastPageP"
          @click="nextPageP"
        >&raquo;</button>
      </div>
    </div>

    
  </div>
</template>

<script>
// import firebase from "firebase";

export default {
  name: "analyticsRatings",
  props: {
    jury: {
      type: Array,
      required: true
    },
    ratesPosters: {
      type: Array,
      required: true
    },
  },
  data: function() {
    return {
      currentPage: {
        p: 0,
        // v: 0
      },
      perPage: {
        p: 20,
        // v: 20
      },
      currentSortP: "rate",
      currentSortDirP: "desc",
      // currentSortV: "rate",
      // currentSortDirV: "desc"
    };
  },
  computed: {
    lastPageP: function() {
      return Math.ceil(this.ratesPosters.length / this.perPage["p"]) - 1;
    },
    currentPosters: function() {
      const myPosters = this.ratesPosters;
      console.log('Length: ', myPosters.length);
      console.log('myPosters ', myPosters);

      return myPosters
        .sort((a, b) => {
          // // console.log('a: ',a[this.currentSortP]);
          // // console.log('b: ',b[this.currentSortP]);
          let modifier = 1;
          if (this.currentSortDirP === "desc") modifier = -1;
          if (a[this.currentSortP].total < b[this.currentSortP].total) return -1 * modifier;
          if (a[this.currentSortP].total > b[this.currentSortP].total) return 1 * modifier;
          return 0;
        })
        .filter((row, index) => { 
          let start = this.currentPage["p"] * this.perPage["p"];
          let end = start + this.perPage["p"];
          if (this.currentPage["p"] === this.lastPageP +1) {
            end = start + (this.ratesPosters.length % this.perPage["p"]);
            // console.log('LAST PAGE: ',end);
          }
          if (index >= start && index < end) {
            return true;
          }
        });
    },
    
  },
  methods: {
    sortByP: function(sortKey) {
      // console.log("entro in sortByP: ",sortKey);

      if (sortKey === this.currentSortP) {
        this.currentSortDirP = this.currentSortDirP === "asc" ? "desc" : "asc";
      }
      this.currentSortP = sortKey;

      var $sortable = $(".sortableP");
      sortKey = sortKey + "P";
      var $this = $(document.getElementById(sortKey));
      // console.log('$this: ',$this)
      var asc = $this.hasClass("asc");
      var desc = $this.hasClass("desc");

      $sortable.removeClass("asc").removeClass("desc");
      if (desc || (!asc && !desc)) {
        $this.addClass("asc");
      } else {
        $this.addClass("desc");
      }
    },
    nextPageP: function() {
      this.currentPage["p"]++;
    },
    prevPageP: function() {
      this.currentPage["p"]--;
    }
    
  },
  
};
</script>

<style scoped>
h3 {
  color: #DA265D;
  font-weight: bold;
  font-size: 2rem;
}
.pag {
  background: transparent;
  color: #DA265D;
}
.pag:hover {
  background: #DA265D;
  color: black;
}
a:hover {
  font-weight: bold;
}

th,
td {
  min-width: 80px;
}

/* Sort Indicators */
th.sortableP,
th.sortableV {
  position: relative;
  cursor: pointer;
}
th.sortableP::after,
th.sortableV::after {
  font-family: FontAwesome;
  content: "\f0dc";
  position: absolute;
  right: 8px;
  color: black;
}
th.sortableP.asc::after,
th.sortableV.asc::after {
  content: "\f0d8";
}
th.sortableP.desc::after,
th.sortableV.desc::after {
  content: "\f0d7";
}
</style>
