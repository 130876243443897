<template>
  <div id="winners">

    <!-- <pre>
      DB RATINGS
      {{ratesPosters}}
    </pre> -->
    <!-- <pre>
      DB WINNERS
      {{ winnersPosters }}
    </pre> -->
    <!-- <pre>
      POSTERS
      {{ currentPosters.length }}
      {{ currentPosters }}
    </pre> -->
    
    <!-- <div v-if="rates[0].posters.length > 0"> -->

      <div class="row py-4 mb-5">
        <div class="col-12 mb-4">
          <h2>The winner is...</h2>  
          <!-- {{bestPoster}} -->
        </div>
        <div class="col-12 col-lg-4">
          <div v-if="Object.keys(bestPoster).length !== 0">
              <a
                v-if="bestPoster.type === 'application/pdf'"
                :href="bestPoster.link"
                class="d-block"
                style="cursor: pointer; box-shadow: 0px 0px 75px gold;"
                target="_blank"
              >
                <pdf :src="bestPoster.link" style="width: 100%"></pdf>
              </a>
              <a
                v-else
                :href="bestPoster.link_original"
                class="d-block"
                style="cursor: pointer; box-shadow: 0px 0px 75px gold;"
                target="_blank"
              >
                <img :src="bestPoster.link_original" style="width: 100%" />
              </a>
          </div>
          <div v-else>
              <a href="#" class="d-block">
                <img src="../../../assets/question-mark.jpg" style="width:100%" />
              </a>
          </div>
        </div>
        <div class="col-12 col-lg-6 offset-lg-1">
          <div v-if="Object.keys(bestPoster).length !== 0">

            <div class="col-12">
              <p class="mb-1">
                <span class="font-weight-bold">Title: </span>
                <span>{{ bestPoster.title }}</span>
              </p>
            </div>
            <div class="col-12">
              <p class="mb-1">
                <span class="font-weight-bold">Description: </span>
                <span>{{ bestPoster.content }}</span>
              </p>
            </div>
            <div class="col-12">
              <p class="mb-1">
                <span class="font-weight-bold">Name: </span>
                <span>{{ bestPoster.name }}</span>
              </p>
            </div>
            <div class="col-12">
              <p class="mb-1">
                <span class="font-weight-bold">Path: </span>
                <span>{{ bestPoster.path }}</span>
              </p>
            </div>
            <div class="col-12">
              <p class="mb-1">
                <span class="font-weight-bold">Type: </span>
                <span>{{ bestPoster.type }}</span>
              </p>
            </div>            
            <div class="col-12">
              <p class="mb-1">
                <span class="font-weight-bold">User ID: </span>
                <span>{{ bestPoster.uID }}</span>
              </p>
            </div>


          </div>
          <div v-else>

            <div class="col-12">
              <p class="mb-1">
                <span class="font-weight-bold">Title: </span>
                <span>???</span>
              </p>
            </div>
            <div class="col-12">
              <p class="mb-1">
                <span class="font-weight-bold">Description: </span>
                <span>???</span>
              </p>
            </div>
            <div class="col-12">
              <p class="mb-1">
                <span class="font-weight-bold">Name: </span>
                <span>???</span>
              </p>
            </div>
            <div class="col-12">
              <p class="mb-1">
                <span class="font-weight-bold">Path: </span>
                <span>???</span>
              </p>
            </div>
            <div class="col-12">
              <p class="mb-1">
                <span class="font-weight-bold">Type: </span>
                <span>???</span>
              </p>
            </div>            
            <div class="col-12">
              <p class="mb-1">
                <span class="font-weight-bold">User ID: </span>
                <span>???</span>
              </p>
            </div>
          </div>
        </div>
      </div>

      
      <div class="row justify-content-center border-bottom py-4 mb-5">
        <div class="col-auto text-left">
          Il numero di poster vincitori deve essere: {{editions.current_edition.num_winners}}
        </div>
        <div class="col auto text-right">
          N° posters: <b>{{numWinners}}</b> / <b>{{editions.current_edition.num_winners}}</b>
        </div>
      </div>


      <form class="" @submit.prevent="saveTheWinners">
        <div class="row mb-5">
          <div class="col">
            <!-- <h3>Winners</h3> -->
            <table id="poster" class="table table-striped">
              <thead>
                <tr>
                  <th>Select</th>
                  <th
                    id="rateP"
                    class="sortableP desc"
                    v-on:click="sortByP('rate')"
                  >
                    Rate
                  </th>
                  <th>Creator</th>
                  <th>Name</th>
                  <th>Surname</th>
                  <th>Path</th>
                  <th>Preview</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="poster in currentPosters" :key="poster.number" :class="poster.status == 'best' ? 'bg-gold' : ''">
                  <!-- <td>{{poster}}</td> -->
                  <td>
                    <label class="container-input">
                      <!-- v-bind:value="poster.path" -->
                      <input
                        type="checkbox"
                        :checked="poster.status != 'finalist'"
                        @click="selectWinners(poster.path, poster.status)"
                        :disabled="poster.status == 'best'"
                      />
                      <span class="checkmark"></span>
                    </label>
                  </td>
                  <td>{{ poster.rate.total }}</td>
                  <td>{{ poster.uID }}</td>
                  <td>{{ poster.uName }}</td>
                  <td>{{ poster.uSurname }}</td>
                  <td>{{ poster.path }}</td>
                  <td>
                    <a
                      v-if="poster.type === 'application/pdf'"
                      :href="poster.link_big"
                      class="d-block"
                      style="cursor: pointer"
                      target="_blank"
                    >
                      <pdf :src="poster.link_big" style="width: 100%"></pdf>
                    </a>
                    <a
                      v-else
                      :href="poster.link_big"
                      class="d-block"
                      style="cursor: pointer"
                      target="_blank"
                    >
                      <img :src="poster.link_big" style="width: 100%" />
                    </a>

                  </td>
                </tr>
              </tbody>
            </table>

            <!-- pagination Posters -->
            <div class="row mb-5">
              <div class="col text-left">
                <button
                  class="pag btn border"
                  :class="currentPage['p'] === 0 ? 'd-none' : 'd-inline'"
                  :disabled="currentPage['p'] === 0"
                  @click="prevPageP"
                >
                  &laquo;
                </button>
              </div>
              <div class="col text-center">
                Pagina {{ currentPage["p"] + 1 }} di {{ lastPageP + 1 }}
              </div>
              <div class="col text-right">
                <button
                  class="pag btn border"
                  :class="currentPage['p'] === lastPageP ? 'd-none' : 'd-inline'"
                  :disabled="currentPage['p'] === lastPageP"
                  @click="nextPageP"
                >
                  &raquo;
                </button>
              </div>
            </div>

          </div>
        </div>

        <!-- Submit + Status -->
        <div class="row">
          <!-- <aside class="col-12 col-lg-4 col-xl-3 offset-xl-1"></aside> -->

          <!-- Submit -->
          <div class="col-12">
            <div class="d-flex justify-content-center mb-5">
              <input
                type="submit"
                class="btn"
                :class="submitStatus == 1 ? 'btn-primary' : 'btn-secondary'"
                :value="submitStatus == 1 ? 'Save' : 'Saving'"
                :disabled="submitStatus == 1 ? false : true"
              />
            </div>
            <!-- Status -->
            <div
              class="mb-5 bg-secondary text-white p-3 border mb-3 rounded"
              v-html="status"
              v-if="status"
            ></div>
          </div>
        </div>
      </form>
  
    <!-- </div>
    <div v-else>

      <div class="page-title row mb-5 mt-5">
        <div class="col-10 offset-1">
          <h2 class="font-weight-bold">Select Winners</h2>
        </div>
      </div>

      <div class="page-title row mb-5 mt-5">
        <div class="col-10 offset-1">
          <p>La giuria non ha ancora concluso la votazione.</p>
        </div>
      </div>
          
    </div> -->


  </div>
</template>



<script>
import firebase from "firebase";
import pdf from "vue-pdf";

export default {
  name: "winners",
  components: {
    pdf,
  },
  data: function () {
    return {
      rates: [],
      ratesPosters: [],
      winnersPosters: [],
      numWinners: 0,
      bestPoster: {},
      // juryRates: [],
      currentPage: {
        p: 0,
        // v: 0,
      },
      perPage: {
        p: 50,
        // v: 20,
      },
      currentSortP: "rate",
      currentSortDirP: "desc",
      // currentSortV: "rate",
      // currentSortDirV: "desc",
      status: "",
      submitStatus: 1,
      id_record_of_winning_posters: "",
    };
  },
  computed: {
    id_current_edition() {
      return this.$store.getters.getIDCurrentEdition; // get id current edition from store
    },
    editions() {
      return this.$store.state.options.contest_editions; // get editions from store
    },
    lastPageP: function () {
      return Math.ceil(this.ratesPosters.length / this.perPage["p"]) - 1;
    },
    currentPosters: function () {
      // console.log("currentPosters");

      const myPosters = this.ratesPosters;
      // console.log("myPosters ", myPosters);

      return myPosters
        .sort((a, b) => {
          // // console.log('a: ',a[this.currentSortP]);
          // // console.log('b: ',b[this.currentSortP]);
          let modifier = 1;
          if (this.currentSortDirP === "desc") modifier = -1;
          if (a[this.currentSortP].total < b[this.currentSortP].total)
            return -1 * modifier;
          if (a[this.currentSortP].total > b[this.currentSortP].total)
            return 1 * modifier;
          return 0;
        })
        .filter((row, index) => {
          let start = this.currentPage["p"] * this.perPage["p"];
          var end = start + this.perPage["p"];
          if (this.currentPage["p"] === this.lastPageP +1) {
            end = start + (this.ratesPosters.length % this.perPage["p"]);
          }
          if (index >= start && index < end) {
            return true;
          }
        });
    },
    

  },
  created() {
    const self = this;

    self.getRecordsFromRatings();
    
    self.getRecordsFromWinners()
      .then( () => {self.setPostersFromWinnersToRatings()} );
  },
  methods: {

    // Con getRecordsFromRatings, prendo tutti i record dalla tabella 'ratings' e popolo l'array 'rates'. Poi prendo l'array 'rates' e creo l'array 'ratesPosters' dove per ogni poster vengono sommati i voti dei giurati
    getRecordsFromRatings() {
      const self = this;

      firebase
        .firestore()
        .collection("ratings")
        // .where("year", "==", this.$store.state.options.year)
        .where("edition_ID", "==", self.editions.current_edition.edition_ID)
        // .where("submitted", '==', true)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((rate) => {
            // // console.log('Rate: ',rate.data());
            self.rates.push(rate.data());
          });
        })
        .then(() => {
          self.createRates();
          self.clearRates();
        });
    },
    createRates: function () {
      this.ratesPosters = [];

      // i = numero di persone che votano
      for (var i = 0; i < this.rates.length; i++) {
        // j = per il numero di posters
        for (var j = 0; j < this.rates[i].posters.length; j++) {
          if (this.rates[i].posters[j] === "") {
            this.rates[i].posters[j].rate = 0;
          }
          const temp = { ...this.rates[i].posters[j] };
          this.ratesPosters.push(temp);
        }
      }
    },
    clearRates: function () {
      let filledPoster = false;
      const ratePosters = [];

      // i = per il numero di posters
      for (var i = 0; i < this.ratesPosters.length; i++) {
        const _path = this.ratesPosters[i].path;
        // p = per il numero
        for (var p = 0; p < ratePosters.length; p++) {
          if (ratePosters[p].path === _path) {
            // ratePosters[p].rate = Number(ratePosters[p].rate) + Number(this.ratesPosters[i].rate);
            // Aesthetics
            ratePosters[p].rate.aesthetics =
              Number(ratePosters[p].rate.aesthetics) +
              Number(this.ratesPosters[i].rate.aesthetics);
            // Theme
            ratePosters[p].rate.theme =
              Number(ratePosters[p].rate.theme) +
              Number(this.ratesPosters[i].rate.theme);
            // Originality
            ratePosters[p].rate.originality =
              Number(ratePosters[p].rate.originality) +
              Number(this.ratesPosters[i].rate.originality);
            // Total
            ratePosters[p].rate.total =
              Number(ratePosters[p].rate.total) +
              Number(this.ratesPosters[i].rate.total);
            filledPoster = true;
            break;
          }
        }
        if (!filledPoster) {
          // means that the rated post does not exist
          // // console.log('rates: ',this.ratesPosters[i]);
          const temp = { ...this.ratesPosters[i] };
          // // console.log('temp: ',temp);
          ratePosters.push(temp);
        }
      }
      // // console.log('ratePosters: ',ratePosters)
      this.ratesPosters = ratePosters;


      // Aggiungo nome e cognome dell'utente all'array dei posters
      // console.log('Rates PRIMA: ',this.ratesPosters);
      this.ratesPosters.forEach(poster => {
        const user_id = poster.uID

        let _poster = poster
        _poster.uName = '';
        _poster.uSurname = '';

        firebase
          .firestore()
          .collection("users")
          .where("uID", "==", user_id)
          .get()
          .then(querySnapshot => {
            querySnapshot.forEach(doc => {
              const uName = doc.data().name;
              const uSurname = doc.data().surname;
              _poster.uName = uName;
              _poster.uSurname = uSurname;
            });
          })
          .catch(function(error) {
            console.log(error);
          });
      });
      // console.log('Rates DOPO: ',this.ratesPosters);
    },



    // Con getRecordsFromWinners, prendo il record nella tabella 'winners' e popolo l'array 'winnersPosters'
    getRecordsFromWinners() {
      const self = this;

      return new Promise((resolve, reject) => {
        
        firebase
        .firestore()
        .collection("winners")
        .where("edition_ID", "==", self.editions.current_edition.edition_ID)
        .get()
        .then(querySnapshot => {
          // Controllo se c'è già un record
          if (!querySnapshot.empty) {
            querySnapshot.forEach(doc => {
              // console.log('doc: ',doc);
              const id = doc.id;
              const data = doc.data();
              self.id_record_of_winning_posters = id;
              // console.log('doc.data().posters_winning: ',data.posters_winning);
              
              data.posters_winning.forEach(poster => {
                // self.winnersPosters.push(poster.uID);
                self.winnersPosters.push(poster.path);
                if(poster.status == "best") {
                  self.bestPoster = poster;
                }
              });

            })
          }
        })
        .then(() => { 
          self.numWinners = self.winnersPosters.length
        })
        .then(docRef => resolve(docRef))
        .catch(error => reject(error));
        
      });
    },


    // Con setPostersFromWinnersToRatings, aggiorno i poster nell'array 'ratesPosters' segnandoli come vincitori se presenti nell'array 'winnersPosters'
    setPostersFromWinnersToRatings() {
      const self = this;

      return new Promise((resolve, reject) => {

        self.winnersPosters.forEach(winner => {
          const path = winner;
          // console.log('winner', winner);
          self.ratesPosters.forEach(poster => {
            if (poster.path == path) {
              // console.log('poster', poster);
              poster.status = "winner";
            }
            if (poster.path == self.bestPoster.path) {
              // console.log('poster', poster);
              poster.status = "best";
            }
          });
        });
        
        resolve();
      
      });
    },



    // Funzione che viene scatenata al click sulla checkbox, quando viene scelto un poster come vincitore
    selectWinners(poster_path, poster_status) {
      const self = this;

      // Cambio lo stato del poster da finalist a winner o viceversa
      for (var i = 0; i < this.currentPosters.length; i++) {
        const _path = this.currentPosters[i].path;
        if (_path == poster_path) {
          if (poster_status == 'finalist') {
            this.currentPosters[i].status = "winner";
            // Conto il numero di poster selezionati
            self.numWinners = self.numWinners + 1;
          } else {
            this.currentPosters[i].status = "finalist";
            // Conto il numero di poster selezionati
            self.numWinners = self.numWinners - 1;
          }
        }
      }
      // console.log('self.numFinalists.length: ',self.numWinners);

    },


    
    // Funzione che viene richiamata al click sul submit e salva i poster scelti come vincitori nella tabella 'winners'
    saveTheWinners() {
      const self = this;
      self.submitStatus = 0;
      
      // Verifico che il numero di poster selezionati per i partner sia uguale al numero scelto dall'admin 
      let num_posters_selected = self.numWinners;
      let num_posters_to_select = self.editions.current_edition.num_winners;
        
      if ( num_posters_selected == num_posters_to_select ) {
        alert('Hai selezionato il numero giusto di poster da mostrare ai parter: '+num_posters_selected);
      }
      else {
        alert('Attenzione! I poster da mostrare ai parter devono essere '+num_posters_to_select+', tu ne hai selezionati '+num_posters_selected+'.');
      }
        
      self.addOrUpdateRecord()
        .then( () => { self.$router.go(); } );

    },

    addOrUpdateRecord() {
      const self = this;

      return new Promise((resolve, reject) => {

        // let posters = self.posters.filter((el) => {
        //   return el.name !== null;
        // });
        let winningPosters = [];
        self.ratesPosters.forEach(poster => {
          if (poster.status == "winner") {
            winningPosters.push(poster);
          }
        });
        // console.log('winningPosters: ',winningPosters);
      
        // console.log('self.id_record_of_winning_posters: ', self.id_record_of_winning_posters);
        if (self.id_record_of_winning_posters) {
          // console.log('True');
          // Esiste già un array contenente i poster che sono stati selezionati come vincitori
          firebase
            .firestore()
            .collection("winners")
            .doc(self.id_record_of_winning_posters)
            .update({
              posters_winning: winningPosters,
            })
            .then(docRef => resolve(docRef))
            .catch(error => reject(error))
        } else {
          // console.log('False');
          // Non esiste ancora un array dei poster vincitori
          firebase
            .firestore()
            .collection("winners")
            .add({
              edition_ID: self.editions.current_edition.edition_ID,
              posters_winning: winningPosters,
            })
            .then(docRef => resolve(docRef))
            .catch(error => reject(error));
        }
        
      });
    },



    sortByP: function (sortKey) {
      // console.log("entro in sortByP: ", sortKey);

      if (sortKey === this.currentSortP) {
        this.currentSortDirP = this.currentSortDirP === "asc" ? "desc" : "asc";
      }
      this.currentSortP = sortKey;

      var $sortable = $(".sortableP");
      sortKey = sortKey + "P";
      var $this = $(document.getElementById(sortKey));
      // console.log("$this: ", $this);
      var asc = $this.hasClass("asc");
      var desc = $this.hasClass("desc");

      $sortable.removeClass("asc").removeClass("desc");
      if (desc || (!asc && !desc)) {
        $this.addClass("asc");
      } else {
        $this.addClass("desc");
      }
    },
    nextPageP: function () {
      this.currentPage["p"]++;
    },
    prevPageP: function () {
      this.currentPage["p"]--;
    },



  },
};
</script>



<style scoped>
h3 {
  color: #da265d;
  font-weight: bold;
  font-size: 2rem;
}
.pag {
  background: transparent;
  color: #da265d;
}
.pag:hover {
  background: #da265d;
  color: black;
}
a:hover {
  font-weight: bold;
}

th,
td {
  min-width: 80px;
  font-size: 0.8rem;
}

.table td {
  vertical-align: middle;
}

/* Sort Indicators */
th.sortableP,
th.sortableV {
  position: relative;
  cursor: pointer;
}
th.sortableP::after,
th.sortableV::after {
  font-family: FontAwesome;
  content: "\f0dc";
  position: absolute;
  right: 8px;
  color: black;
}
th.sortableP.asc::after,
th.sortableV.asc::after {
  content: "\f0d8";
}
th.sortableP.desc::after,
th.sortableV.desc::after {
  content: "\f0d7";
}

/* INPUT */
/* The container */
.container-input {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container-input input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: -8px;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #bbb;
}

/* On mouse-over, add a grey background color */
.container-input:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container-input input:checked ~ .checkmark {
  background-color: #da265d;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container-input input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container-input .checkmark:after {
  left: 10px;
  top: 6px;
  width: 7px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
</style>
