<template>
  <div>
    <div id="read" class="border-bottom-black mb-5">
      <div class="row">
        <div class="col-12 d-flex flex-column justify-content-center">
          <!-- Submissions -->
          <div class="row" v-if="posters[0].size">
            <div class="col-12">
              <div class="row">
                <div class="col-12">
                  <div class="w-50">
                    <h2 class="">Posters</h2>
                    <p class="font-secondary">
                      Users can upload the posters whenever they want, until the
                      deadline:
                      <span class="font-weight-bold">August 17, 2024</span>
                      After the deadline all the projects inside this section
                      will be definitive.
                    </p>
                  </div>
                </div>

                <div
                  class="col-4"
                  v-for="(poster, index) in 3"
                  :key="`poster-${poster}`"
                >
                  <div class="row">
                    <div class="col-12 pb-5 mb-5" v-if="posters[index].size">
                      <!-- Preview -->
                      <div
                        class="d-flex justify-content-center"
                        v-if="posters[index].link_original !== null"
                      >
                        <div class="d-flex justify-content-center">
                          <a
                            v-if="posters[index].type === 'application/pdf'"
                            class="d-block"
                            style="width: 50%; cursor: auto"
                          >
                            <pdf
                              :src="posters[index].link_original"
                              style="width: 100%"
                            ></pdf>
                          </a>
                          <a v-else class="d-block" style="cursor: auto">
                            <img
                              :src="posters[index].link_big"
                              style="width: 100%"
                            />
                          </a>
                        </div>
                      </div>

                      <!-- Title -->
                      <div class="">
                        <h2 class="font-size-4rem mb-4">
                          Poster #{{ poster }}
                        </h2>
                      </div>

                      <!-- Information -->
                      <div>
                        <div class="">
                          <p class="mb-2">
                            <span class="text-uppercase font-weight-bold"
                              >Name:
                            </span>
                            <span class="text-uppercase">{{
                              posters[index].name
                            }}</span>
                          </p>
                        </div>
                        <div class="">
                          <p class="mb-2">
                            <span class="text-uppercase font-weight-bold"
                              >Title:
                            </span>
                            <span class="text-uppercase">{{
                              posters[index].title
                            }}</span>
                          </p>
                        </div>
                        <div class="">
                          <p class="mb-2">
                            <span
                              class="d-block text-uppercase font-weight-bold"
                              >Description:
                            </span>
                            <span class="font-secondary">{{
                              posters[index].content
                            }}</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- SectionAI era qui! -->
          </div>

          <div class="row" v-else>
            <div class="col-12 col-lg-6 pb-3 mb-5">
              <div class="mb-5">
                <h2 class="">Posters</h2>
                <p class="font-secondary">
                  Users can upload the posters whenever they want, until the
                  deadline:
                  <span class="font-weight-bold">August 17, 2024</span>
                  After the deadline all the projects inside this section will
                  be definitive.
                </p>
              </div>
              <div class="">
                <h2 class="font-size-4rem text-uppercase">
                  You have not uploaded any poster yet
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Section AI -->
    <!-- <div class="">
      <ReadAI />
    </div> -->
  </div>
</template>

<script>
import store from "@/store";
import firebase from "firebase";
import pdf from "vue-pdf";
// import ReadAI from "@/components/Content/User/ReadAI";

export default {
  name: "Read",
  components: {
    pdf,
    // ReadAI,
  },
  data() {
    return {
      posts: {
        submissions: [],
      },
      submissons_length: 0,
      posters: [
        {
          title: null,
          content: null,
          name: null,
          size: null,
          type: null,
          path: null,
          link: null,
          status: null,
        },
        {
          title: null,
          content: null,
          name: null,
          size: null,
          type: null,
          path: null,
          link: null,
          status: null,
        },
        {
          title: null,
          content: null,
          name: null,
          size: null,
          type: null,
          path: null,
          link: null,
          status: null,
        },
      ],
      info: {
        number_of_posters: 0,
        year: null,
      },
    };
  },
  computed: {
    users_can_upload() {
      return this.$store.state.options.roles_active.user_can_upload;
    },
    user_info() {
      return this.$store.state.user; // get user from store
    },
    id_current_edition() {
      return this.$store.getters.getIDCurrentEdition; // get id current edition from store
    },
    editions() {
      return this.$store.state.options.contest_editions; // get editions from store
    },
    posters_info() {
      return this.$store.getters.getAllPostersInfo; // get posters from store
    },
    posters_current_edition() {
      return this.$store.getters.getPostersCurrentEdition; // get posters current edition from store
    },
    info_current_edition() {
      return this.$store.getters.getInfoCurrentEdition; // get info current edition from store
    },
  },
  created() {
    const self = this;
    self.fillPostersArray();
  },
  filters: {
    moment: function (date) {
      return moment(date).format("MMMM, Do YYYY");
    },
  },

  methods: {
    /*
    Populate local array 'Posters' with posters into store
    */
    fillPostersArray() {
      let obj = { ...this.posters_info.editions };
      let property = this.id_current_edition;
      if (property in obj) {
        this.info.number_of_posters =
          this.info_current_edition.number_of_posters;
      }

      for (let i = 0; i < this.info.number_of_posters; i++) {
        // Popolo array locale dei posters
        this.posters[i] = this.posters_current_edition[i];
        // console.log("poster-current: " + this.posters[i]);
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
