import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const options = {
    contest_active: null,
    roles_active: {
        admin: null,
        user_can_login: null,
        user_can_register: null,
        user_can_upload: null,
        selector: null,
        jury: null,
        analytics: null,
        partner: null
    },
    contest_editions: {
        current_edition: {
            edition_ID: null,
            num_finalists: null,
            num_winners: null,
            title: null,
            description: null,
            year: null,
        },
        past_editions: {
            ph_0000: {
                id: 'ph_0000',
                title: null,
                description: null,
                year: '0000',
            },
        }
    },
}

// Creo l'oggetto user che ho nella tabella Users
const user = {
    uID: null,
    role: null,
    name: null,
    surname: null,
    dateOfBirth: null,
    gender: null,
    phone: null,
    nationality: null,
    workingPosition: null,
    address: null,
    zipCode: null,
    city: null,
    country: null,
    representative: false,
    groupName: null,
    groupMembers: null,
    dateCreate: null,
    dateUpdate: null,
    // dateUpload: null,
}

// Creo l'oggetto poster che ho nella tabella Submissions
const posters = {
    uID: null,
    uName: null,
    uSurname: null,
    editions: {
        ph_0000: {
            posters: [
                { title: null, content: null, name: null, size: null, type: null, path: null, link: null, link_original: null, link_small: null, link_big: null, status: null },
                { title: null, content: null, name: null, size: null, type: null, path: null, link: null, link_original: null, link_small: null, link_big: null, status: null },
                { title: null, content: null, name: null, size: null, type: null, path: null, link: null, link_original: null, link_small: null, link_big: null, status: null },
            ],
            // posters_AI: [
            //     { title: null, content: null, name: null, size: null, type: null, path: null, link: null, link_original: null, link_small: null, link_big: null, status: null },
            // ],
            info: {
                num_posters: null,
                // num_posters_AI: null,
            }
        },
    },
}

export default new Vuex.Store({
    state: {
        // Contest settings
        options: options,
        // Data of the logged in user
        user: user,
        // Posters of the logged in user
        posters: posters
    },
    getters: {
        getIDCurrentEdition: state => {
            return state.options.contest_editions.current_edition.edition_ID;
        },
        getAllPostersInfo: state => {
            return state.posters;
        },
        getPostersCurrentEdition: state => {
            let this_edition = state.options.contest_editions.current_edition.edition_ID;
            return state.posters.editions[this_edition].posters;
        },
        // getPostersAICurrentEdition: state => {
        //     let this_edition = state.options.contest_editions.current_edition.edition_ID;
        //     return state.posters.editions[this_edition].posters_AI;
        // },
        getInfoCurrentEdition: state => {
            let this_edition = state.options.contest_editions.current_edition.edition_ID;
            return state.posters.editions[this_edition].info;
        },
    },
    mutations: {
        // Options
        setContestIsActive(state, value) {
            state.options.contest_active = value
        },
        setRolesActive(state, value) {
            state.options.roles_active = value
        },
        setContestEditions(state, value) {
            state.options.contest_editions = value
        },
        setYear(state, value) {
            state.options.year = value
        },

        // User
        resetUser(state) {
            state.user = user
        },
        setUser(state, value) {
            state.user = value
        },
        setUserID(state, value) {
            state.user.uID = value
        },
        setUserName(state, value) {
            state.user.name = value
        },
        setUserSurname(state, value) {
            state.user.surname = value
        },
        setUserRole(state, value) {
            state.user.role = value
        },

        // Poster
        resetPosters(state) {
            state.posters = posters
        },
        setPosters(state, value) {
            state.posters = value
        },
        setPostersCurrentEdition(state, value) {
            state.posters.editions.edizione1.posters = value
        },
    },
    actions: {},
    modules: {}
})