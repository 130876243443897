<template>
  <div>
    <div v-if="users_can_upload">
      <div id="upload" class="border-bottom-black">
        <form class="row position-relative" @submit.prevent="createPost">
          <div class="col-12">
            <!-- POSTERS -->
            <div class="row mb-5">
              <div class="col-12 mb-5">
                <div class="w-50">
                  <h2 class="">Posters</h2>
                  <p class="font-secondary">
                    Upload your poster in one of these formats:
                    <span class="font-weight-bold">jpg</span>,
                    <span class="font-weight-bold">jpeg</span> or
                    <span class="font-weight-bold">png</span>.
                    <span>
                      We would prefer to have your poster(s) in jpg or png to
                      speed up the selection process for the jury. <br />File
                      dimensions has to be
                      <span class="font-weight-bold">4134px x 5906px</span> and
                      file size has to be under
                      <span class="font-weight-bold">10MB (10240KB)</span>.
                    </span>
                    Uploads projets no later than:
                    <span class="font-weight-bold">August 17, 2024</span>
                  </p>
                </div>
              </div>

              <!-- POSTERS LOOP -->
              <div
                class="col-4 position-relative"
                v-for="(poster, index) in posters.length"
                :key="`poster-${poster}`"
              >
                <div class="row mb-4">
                  <div class="col-12">
                    <!-- Preview -->
                    <div class="border-black">
                      <div class="" v-if="posters[index].link_original !== null">
                        <div class="d-flex justify-content-center">
                          <a
                            v-if="posters[index].type === 'application/pdf'"
                            class="d-block"
                            style="width: 50%; cursor: auto"
                          >
                            <pdf
                              :src="posters[index].link_original"
                              style="width: 100%"
                            ></pdf>
                          </a>
                          <a
                            v-else
                            class="d-block"
                            style="width: 100%; cursor: auto"
                          >
                            <img
                              class=""
                              :src="posters[index].link_big"
                              style="width: 100%"
                            />
                          </a>
                        </div>
                      </div>
                    </div>

                    <!-- Title -->
                    <div class="">
                      <h2 class="font-size-4rem mb-4">Poster #{{ poster }}</h2>
                      <span
                        class="d-block up-progress text-uppercase font-weight-bold"
                        v-if="posters[index].name"
                      >
                        Name: {{ posters[index].name }}
                      </span>
                    </div>

                    <!-- Information -->
                    <div class="" v-if="posters[index].size">
                      <div class="mb-3">
                        <input
                          class="form-control mb-4"
                          type="text"
                          name="title"
                          placeholder="Title *"
                          v-model="posters[index].title"
                          required
                        />
                      </div>
                      <div class="mb-3">
                        <textarea
                          class="mb-4"
                          name="content"
                          v-model="posters[index].content"
                          maxlength="300"
                          rows="4"
                          placeholder="Tell us something about your poster *"
                          required
                        ></textarea>
                      </div>
                    </div>

                    <!-- Button upload -->
                    <!-- rules="ext:jpeg,jpg,png,pdf|size:10240|dimensions:4134,5906" -->
                    <ValidationProvider
                      class="d-block my-4"
                      ref="validator"
                      name="validator"
                      rules="ext:jpeg,jpg,png|size:10240"
                      v-slot="{ errors, validate }"
                      v-if="!posters[index].name"
                    >
                      <input
                        class="btn btn-upload border w-100"
                        type="file"
                        @change="changeInput($event, validate, index)"
                        :ref="`fileInput-posters-${index}`"
                        :name="`Poster #${poster}`"
                        :disabled="info.number_of_posters < index"
                      />
                      <span class="d-block">{{ errors[0] }}</span>
                    </ValidationProvider>

                    <!-- Button remove -->
                    <div v-else class="my-4">
                      <button
                        class="btn btn-remove border"
                        @click.prevent="
                          emptyPostersArray('posters', index, true)
                        "
                      >
                        Remove file
                      </button>
                    </div>
                  </div>

                  <!-- Loader image -->
                  <div
                    v-if="submitStatus == 0"
                    class="col-12"
                    style="
                      background-color: rgba(253, 247, 239, 0.5);
                      position: absolute;
                      top: 0;
                      left: 0;
                      right: 0;
                      bottom: 0;
                    "
                  >
                    <div class="" style="position: relative; height: 100%">
                      <img
                        src="../../../assets/loader.gif"
                        style="
                          position: absolute;
                          top: 50%;
                          left: 50%;
                          transform: translate(-50%, -50%);
                          width: 33%;
                        "
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Submit -->
            <div class="row mb-5">
              <div class="col-12">
                <div class="d-flex justify-content-start mb-5">
                  <input
                    type="submit"
                    class="btn btn-submit"
                    :class="
                      submitStatus == 1 ? 'btn-primary w-33' : 'btn-secondary'
                    "
                    :value="
                      submitStatus == 1
                        ? 'Submit'
                        : 'Submitting. Please wait a few seconds.'
                    "
                    :disabled="submitStatus == 1 ? false : true"
                  />
                </div>
              </div>
            </div>

          </div>
        </form>
      </div>

      <!-- Section AI -->
      <!-- <UploadAI :posters="posters" /> -->
    </div>
    <div v-else>
      <UploadClosed />
    </div>
  </div>
</template>


<script>
import firebase from "firebase";
import pdf from "vue-pdf";
// import UploadAI from "@/components/Content/User/UploadAI";
import UploadClosed from "@/components/Content/User/UploadClosed";

// VeeValidate
// https://vee-validate.logaretm.com/v3/guide/rules.html#rules
import { ValidationProvider } from "vee-validate/dist/vee-validate.full.esm";
import { extend } from "vee-validate";
import * as rules from "vee-validate/dist/rules";
import { messages } from "vee-validate/dist/locale/en.json";
Object.keys(rules).forEach((rule) => {
  extend(rule, {
    ...rules[rule], // copies rule configuration
    message: messages[rule], // assign message
  });
});

export default {
  name: "Upload",
  components: {
    pdf,
    // UploadAI,
    UploadClosed,
    ValidationProvider,
  },
  data() {
    return {
      uid: null,
      submitStatus: 1,
      edition_name: "",
      posters: [
        {
          title: null,
          content: null,
          name: null,
          size: null,
          type: null,
          path: null,
          link: null,
          link_original: null,
          link_small: null,
          link_big: null,
          status: null,
        },
        {
          title: null,
          content: null,
          name: null,
          size: null,
          type: null,
          path: null,
          link: null,
          link_original: null,
          link_small: null,
          link_big: null,
          status: null,
        },
        {
          title: null,
          content: null,
          name: null,
          size: null,
          type: null,
          path: null,
          link: null,
          link_original: null,
          link_small: null,
          link_big: null,
          status: null,
        },
      ],
      info: {
        number_of_posters: 0,
        edition_ID: null,
      },
      initial_posters: [],
      final_posters: [],
      posters_temporarily_deleted: [],
      posters_temporarily_uploaded: [],
      posters_to_be_deleted_in_storage: [],
      posters_to_be_uploaded_in_storage: [],
    };
  },
  computed: {
    users_can_upload() {
      return this.$store.state.options.roles_active.user_can_upload;
    },
    user_info() {
      return this.$store.state.user; // get user from store
    },
    id_current_edition() {
      return this.$store.getters.getIDCurrentEdition; // get id current edition from store
    },
    editions() {
      return this.$store.state.options.contest_editions; // get editions from store
    },
    posters_info() {
      return this.$store.getters.getAllPostersInfo; // get posters from store
    },
    posters_current_edition() {
      return this.$store.getters.getPostersCurrentEdition; // get posters current edition from store
    },
    info_current_edition() {
      return this.$store.getters.getInfoCurrentEdition; // get info current edition from store
    },
  },
  created() {
    const self = this;
    this.uid = this.$store.state.user.uID;
    this.name = this.$store.state.user.name;
    this.surname = this.$store.state.user.surname;
    this.edition_name = this.editions.current_edition.edition_ID;

    // Populate local array 'Posters' with posters into store
    self.fillPostersArray();
  },
  methods: {
    /*
    Populate local array 'Posters' with posters into store
    */
    fillPostersArray() {
      const self = this;
      this.info.edition_ID = this.editions.current_edition.edition_ID;

      let obj = { ...this.posters_info.editions };
      let property = this.id_current_edition;
      if (property in obj) {
        this.info.number_of_posters =
          this.info_current_edition.number_of_posters;
      }

      for (let i = 0; i < this.info.number_of_posters; i++) {
        // Popolo array locale dei posters
        this.posters[i] = this.posters_current_edition[i];

        // Popolo un array con i poster appena letti dallo store
        let path = this.posters_current_edition[i].path;
        this.initial_posters[i] = path;
      }
    },

    /*
    CONTROLLIAMO CHE IL FILE INSERITO RISPETTI LE REGOLE: ext:jpeg,jpg,png,pdf|size:10240|dimensions:4134,5906
    */
    changeInput: async function (value, validate, index) {
      const _this = this;
      await this.updateinput(_this, value, validate, index);
      // Other logic not relevant to the issue
    },
    async updateinput(_this, value, validate, index) {
      const self = this;
      const type = "posters";

      const { valid } = await validate(value);
      if (!valid) {
        console.log("NOT VALID");
        this.emptyPostersArray("posters", index, false);
      } else {
        console.log("VALID");
        this.info.number_of_posters = this.info.number_of_posters + 1;

        const file_data = value.target.files[0];

        const edition_ID = this.editions.current_edition.edition_ID;
        const uid = this.$store.state.user.uID;
        const path =
          edition_ID +
          "/" +
          uid +
          "/" +
          type +
          "_" +
          index +
          "_" +
          file_data.name;

        self[type][index].path = path;
        self[type][index].name = file_data.name;
        self[type][index].size = (file_data.size / 1024).toFixed(2);
        self[type][index].type = file_data.type;

        let single_file = {
          poster_index: index,
          poster_path: path,
          poster_file: file_data,
        };

        // SET FILE DATA INTO POSTER
        let array_files = this.posters_temporarily_uploaded;
        array_files.push(single_file);
      }
    },

    /*
    Pulisco l'array locale che viene utilizzato temporaneamente per il caricamento dei poster
    */
    emptyPostersArray(type, index, value) {
      this[type][index].title = null;
      this[type][index].content = null;
      this[type][index].name = null;
      this.posters[index].name = null;
      this[type][index].size = null;
      this[type][index].type = null;
      this.posters_temporarily_deleted.push(this[type][index].path);
      this[type][index].path = null;
      this[type][index].link = null;
      this[type][index].link_original = null;

      if (value) {
        this.info.number_of_posters = this.info.number_of_posters - 1;
      }
    },

    /*
    Aggiungo o modifico il record nella tabella Submissions, con le informazioni dei poster dell'utente loggato 
    */
    createPost: function () {
      let self = this;

      let posters = self.posters.filter((el) => {
        return el.name !== null;
      });
      // console.log('Posters Finale: ',posters);

      if (!posters.length > 0) {
        alert("Upload something, please");
        self.submitStatus = 1;
      } else {
        self.submitStatus = 0;

        // DELETE FILE INTO STORAGE
        self.checkPostersToBeDeleted();

        self
          .checkPostersToBeUploaded()
          // SONO STATI CARICATI I FILE NELLO STORAGE E SONO STATE CREATE LE THUMB
          .then(function () {
            self.getLinkThumbnails().then(function () {
              self.saveDataIntoDatabase();
            });
          });
        // SONO STATI INSERITI GLI URL DELLE THUMB IN POSTER
      }
    },

    /*
    CONTROLLA I FILE CHE DOBBIAMO CANCELLARE
    */
    checkPostersToBeDeleted() {
      // console.log("INITIAL: ", this.initial_posters);
      let arr1 = this.initial_posters;
      // console.log("Start: ", arr1);
      let arr2 = this.posters_temporarily_deleted;
      // console.log("current: ", arr2);
      let difference = arr1.filter((x) => arr2.includes(x));
      // console.log("difference: ", difference);
      this.posters_to_be_deleted_in_storage = difference;

      for (let i = 0; i < difference.length; i++) {
        for (let j = 0; j < difference.length; j++) {
          let path = difference[i];
          this.deleteFile(path);
        }
      }

      return true;
    },

    /*
    DELETE FILE INTO STORAGE
    */
    deleteFile(path_file_into_storage) {
      var storage = firebase.storage();
      var storageRef = storage.ref();
      var desertRef = storageRef.child(path_file_into_storage);
      // Delete the file
      desertRef
        .delete()
        .then(() => {
          // File deleted successfully
          console.log("File deleted successfully");
        })
        .catch((error) => {
          // An error occurred
          console.log("Error deleteFile: ", error);
        });
    },

    /*
    CONTROLLA I FILE CHE BISOGNA CARICARE
    */
    checkPostersToBeUploaded: function () {
      return new Promise((resolve, reject) => {
        let self = this;

        let posters_uploaded = self.posters_to_be_uploaded_in_storage;
        for (let i = 0; i < self.posters_temporarily_uploaded.length; i++) {
          let path = self.posters_temporarily_uploaded[i].poster_path;
          // console.log('path: ',path);
          for (let index = 0; index < self.posters.length; index++) {
            // console.log('path-index: ',self.posters[index].path);
            if (path == self.posters[index].path) {
              posters_uploaded.push(self.posters_temporarily_uploaded[i]);
            }
          }
          // console.log("posters_uploaded: ", posters_uploaded);
        }

        let promises = [];

        for (
          let i = 0;
          i < self.posters_to_be_uploaded_in_storage.length;
          i++
        ) {
          let index = self.posters_to_be_uploaded_in_storage[i].poster_index;
          let path = self.posters_to_be_uploaded_in_storage[i].poster_path;
          let file = self.posters_to_be_uploaded_in_storage[i].poster_file;
          // console.log("Path: " + path + " File: " + file);
          promises.push(self.uploadFileInStorage(index, path, file));
        }

        console.log("Promises file: ", promises);

        Promise.all(promises)
          .then((results) => {
            // console.log("All done", results);
            resolve(results);
          })
          .catch((error) => {
            // console.log("All done", error);
            reject("All done ", error);
          });
      });
    },

    /*
    UPDATE FILE INTO STORAGE
    */
    uploadFileInStorage: function (i, path_file_into_storage, file) {
      // console.log('IL FILE: ', file);

      return new Promise((resolve, reject) => {
        let self = this;

        firebase
          .storage()
          .ref(path_file_into_storage)
          .put(file)
          .then(() => {
            // console.log("File uploaded successfully");

            firebase
              .storage()
              .ref(path_file_into_storage)
              .getDownloadURL()
              .then((url) => {
                self.posters[i].link_original = url;
              })
              .then((i) => {
                resolve(i);
              })
              .catch((error) => {
                reject("Error: ", error);
              });
          })
          .catch((error) => {
            reject("Error: ", error);
          });
      });
    },

    /*
    GET URL THUMBNAILS
    */
    getLinkThumbnails: function () {
      let self = this;

      return new Promise((resolve, reject) => {
        let promises = [];

        for (let i = 0; i < self.posters.length; i++) {
          if (
            self.posters[i].path != null &&
            self.posters[i].type != "application/pdf"
          ) {
            let path_file_into_storage = self.posters[i].path;
            // console.log('path_file_into_storage: ', path_file_into_storage);
            // Example: path_file_into_storage: ph_2020/jNX7QiKZxASjue1E6EK43ZHN9qI3/posters_0_ph-00.jpg

            let path_file_thumb_small = path_file_into_storage.replace(
              /\.[^/.]+$/,
              "_280x400.webp"
            );
            console.log("path_file_thumb_small: ", path_file_thumb_small);

            let path_file_thumb_big = path_file_into_storage.replace(
              /\.[^/.]+$/,
              "_840x1200.webp"
            );
            console.log("path_file_thumb_big: ", path_file_thumb_big);

            promises.push(self.getLinkThumbnailSmall(i, path_file_thumb_small));
            promises.push(self.getLinkThumbnailBig(i, path_file_thumb_big));
          }
        }

        console.log("Promises thumbnails: ", promises);

        Promise.all(promises)
          .then((results) => {
            console.log("All done, results: ", results);
            resolve(results);
          })
          .catch((error) => {
            console.log("All done, error: ", error);
            reject("All done ", error);
          });
      });
    },

    /*
    GET URL THUMB SMALL
    */
    getLinkThumbnailSmall: function (i, path_file_thumb) {
      let self = this;

      return new Promise((resolve, reject) => {
        setTimeout(() => {
          firebase
            .storage()
            .ref(path_file_thumb)
            .getDownloadURL()
            .then((url) => {
              self.posters[i].link_small = url;
            })
            .then((i) => {
              resolve(i);
            })
            .catch((error) => {
              reject("Error: ", error);
            });
        }, 15000);
      });
    },

    /*
    GET URL THUMB BIG
    */
    getLinkThumbnailBig: function (i, path_file_thumb) {
      let self = this;

      return new Promise((resolve, reject) => {
        setTimeout(() => {
          firebase
            .storage()
            .ref(path_file_thumb)
            .getDownloadURL()
            .then((url) => {
              self.posters[i].link_big = url;
            })
            .then((i) => {
              resolve(i);
            })
            .catch((error) => {
              reject("Error: ", error);
            });
        }, 15000);
      });
    },

    /*
    SAVE POSTER INFORMATIONS ON DATABASE "SUBMISSIONS"
    */
    saveDataIntoDatabase: function () {
      let self = this;

      let posters = self.posters.filter((el) => {
        return el.name !== null;
      });
      // console.log("Posters Finale: ", posters);
      console.log("Posters array length: ", posters.length);
      self.info.number_of_posters = posters.length;

      if (!posters.length > 0) {
        alert("Upload something, please");
        self.submitStatus = 1;
      } else {
        self.submitStatus = 0;

        const edition = {
          [self.edition_name]: {
            posters: posters,
            info: self.info,
          },
        };
        // console.log(edition);

        const exist_user_submissions = self.posters_info.uID;
        if (exist_user_submissions == null) {
          // Creo un nuovo record nella tabella Submissions se non esiste già
          firebase
            .firestore()
            .collection("submissions")
            .add({
              uID: self.user_info.uID,
              uName: self.user_info.name,
              uSurname: self.user_info.surname,
              // posters: posters,
              editions: edition,
            })
            .then(() => {
              console.log("Data save successfully");
              setTimeout(() => {
                self.$router.go();
              }, 2000);
            });
        } else {
          // Modifico il record nella tabella Submissions se esiste già
          firebase
            .firestore()
            .collection("submissions")
            .where("uID", "==", self.posters_info.uID)
            .get()
            .then((querySnapshot) => {
              querySnapshot.forEach((doc) => {
                // console.log("DOC:", doc.id);
                firebase
                  .firestore()
                  .collection("submissions")
                  .doc(doc.id)
                  .set(
                    {
                      uID: self.user_info.uID,
                      uName: self.user_info.name,
                      uSurname: self.user_info.surname,
                      // posters: posters,
                      editions: edition,
                    },
                    { merge: true }
                  )
                  .then(() => {
                    console.log("Data save successfully");
                    setTimeout(() => {
                      self.$router.go();
                    }, 2000);
                  });
              });
            });
        }
      }
    },
  }, // End methods
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
