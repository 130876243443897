<template>
  <div id="register">
    <div v-if="users_can_register">
      <div class="row">
        <div class="col-12 col-lg-6 pl-0">
          <div class="position-sticky" style="top: 0">
            <img
              class="w-100 vh-100 object-fit-cover"
              src="@/assets/PH-login.jpg"
            />
          </div>
        </div>
        <div class="col-12 col-lg-6 padding-for-header my-5">
          <form class="row page-content" @submit.prevent="doRegister">
            <!-- Login info -->
            <div
              class="col-12 col-lg-8 offset-lg-1 d-flex flex-column justify-content-center form-group pb-5 mb-5"
            >
            
              <div class="row">
                <div class="col-12">
                  <div class="mb-5">
                    <h2 class="page-title text-center text-uppercase">
                      Login info
                    </h2>
                    <p class="text-center text-uppercase">
                      These will be your login username and password
                    </p>
                  </div>
                </div>
                <div class="col-12">
                  <div class="">
                    <!-- <label>E-mail</label> -->
                    <input
                      class="mb-4"
                      type="email"
                      name="email"
                      placeholder="Email"
                      v-model="auth.email"
                      required
                    />
                  </div>
                  <div class="">
                    <!-- <label>Password</label> -->
                    <input
                      class="mb-4"
                      type="password"
                      name="password"
                      placeholder="Password"
                      v-model="auth.password"
                      required
                      autocomplete="on"
                    />
                  </div>
                </div>
              </div>
            </div>

            <!-- Personal Info -->
            <div
              class="col-12 col-lg-8 offset-lg-1 d-flex flex-column justify-content-center form-group"
            >

              <div class="row">
                <div class="col-12">
                  <div class="mb-5">
                    <h2 class="page-title text-center text-uppercase">
                      Personal info
                    </h2>
                    <p class="text-center text-uppercase">* Required fields</p>
                  </div>
                </div>

                <div class="col-12">
                  <div class="">
                    <!-- <label>* Name</label> -->
                    <input
                      class="mb-4"
                      type="text"
                      name="name"
                      placeholder="Name *"
                      v-model="user.name"
                      required
                    />
                  </div>
                  <div class="">
                    <!-- <label>* Surname</label> -->
                    <input
                      class="mb-4"
                      type="text"
                      name="surname"
                      placeholder="Surname *"
                      v-model="user.surname"
                      required
                    />
                  </div>
                  <div class="">
                    <!-- <label class="d-block">* Date of Birth</label> -->
                    <ValidationProvider
                      name="date"
                      rules="date"
                      v-slot="{ errors }"
                    >
                      <input
                        class="mb-4"
                        type="text"
                        name="date"
                        placeholder="Date of Birth (dd/mm/yyyy) *"
                        v-model="user.dateOfBirth"
                        required
                      />
                      <span class="d-block small mb-4" >{{
                        errors[0]
                      }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="">
                    <!-- <label>* Gender</label> -->
                    <select
                      class="custom-select mb-4"
                      name="gender"
                      placeholder=""
                      v-model="user.gender"
                      required
                    >
                    <option value="" disabled selected>Gender *</option>
                      <option value="Female">Female</option>
                      <option value="Male">Male</option>
                      <option value="Other">Other</option>
                    </select>
                  </div>
                  <div class="">
                    <!-- <label>* Phone</label> -->
                    <VuePhoneNumberInput
                      class="pb-3 mb-4"
                      placeholder="Phone *"
                      v-model="user.phone"
                      required
                    />
                  </div>
                  <div class="">
                    <!-- <label>* Nationality</label> -->
                    <select
                      class="custom-select mb-4"
                      name="nationality"
                      placeholder=""
                      v-model="user.nationality"
                      required
                    >
                      <option value="" disabled selected>Nationality *</option>
                      <option
                        v-for="country in countries"
                        :value="country.label"
                        :key="country.value"
                      >
                        {{ country.label }}
                      </option>
                    </select>
                  </div>
                  <div class="">
                    <!-- <label>* Working position</label> -->
                    <select
                      class="custom-select mb-4"
                      name="workingPosition"
                      placeholder=""
                      v-model="user.workingPosition"
                      required
                    >
                    <option value="" disabled selected>Working position *</option>
                      <option value="Student">Student</option>
                      <option value="Worker">Worker</option>
                      <option value="Other">Other</option>
                    </select>
                    <br />
                  </div>

                  <div class="">
                    <input
                      class="mb-4"
                      type="checkbox"
                      name="representative"
                      v-model="user.representative"
                    />
                    <span class="text-uppercase">
                    I am a representative of a group
                    </span>
                  </div>
                  <div class="" v-if="user.representative">
                    <input
                      class="mb-4"
                      type="text"
                      name="group"
                      v-model="user.groupName"
                      placeholder="Group name (or the agency)"
                    />
                    <!-- <textarea
                      class="mb-4"
                      name="groupMembers"
                      v-model="user.groupMembers"
                      placeholder="Name of the group members (separated by comma)"
                    ></textarea> -->
                    <input
                      class="mb-4"
                      type="text"
                      name="groupMembers"
                      v-model="user.groupMembers"
                      placeholder="Name of the group members (separated by comma)"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              class="col-12 col-lg-8 offset-lg-1 d-flex flex-column justify-content-center form-group"
            >
              <div class="row pt-4 mb-4">
                <div class="col-12">
                  <div class="mb-5">
                    <p class="font-size-2rem text-center text-uppercase">
                      Fill in your shipping details for sending our 2024 Calendar's award
                    </p>
                  </div>
                </div>

                <div class="col-12">
                  <div class="">
                    <!-- <label>* Address</label> -->
                    <input
                      class="mb-4"
                      type="text"
                      name="address"
                      v-model="user.address"
                      placeholder="Address *"
                      required
                    />
                  </div>
                  <div class="">
                    <!-- <label>* ZIP Code</label> -->
                    <input
                      class="mb-4"
                      type="text"
                      name="zipCode"
                      v-model="user.zipCode"
                      placeholder="Zip code *"
                      required
                    />
                  </div>
                  <div class="">
                    <!-- <label>* City</label> -->
                    <input
                      class="mb-4"
                      type="text"
                      name="city"
                      v-model="user.city"
                      placeholder="City *"
                      required
                    />
                  </div>
                  <div class="">
                    <!-- <label>* Country</label> -->
                    <select
                      class="custom-select mb-4"
                      name="country"
                      placeholder=""
                      v-model="user.country"
                      required
                    >
                      <option value="" disabled selected>Country *</option>
                      <option
                        v-for="country in countries"
                        :value="country.label"
                        :key="country.value"
                      >
                        {{ country.label }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <!-- Submit + Status -->
            <div
              class="col-12 col-lg-8 offset-lg-1 d-flex flex-column justify-content-center form-group"
            >
              <div class="row form-group">

                <div class="col-12">
                  <div class="mb-5">
                    <input type="checkbox" name="privacy" required /> 
                    <span class="text-uppercase"> I accept
                    <a
                      href="https://www.posterheroes.org/terms-of-use/"
                      target="_blank"
                      >Privacy Policy and Regulation</a
                    >.
                    </span>
                  </div>
                </div>

                <!-- Submit -->
                <div class="col-12">
                  <!-- Button -->
                  <div class="mb-3">
                    <input
                      type="submit"
                      class="btn"
                      :class="
                        submitStatus == 1 ? 'btn-primary' : 'btn-secondary'
                      "
                      :value="submitStatus == 1 ? 'Register' : 'Registering'"
                      :disabled="submitStatus == 1 ? false : true"
                    />
                  </div>
                  <!-- Status -->
                  <div
                    class="mb-5 bg-secondary text-white p-3 border mb-3 rounded"
                    v-html="status"
                    v-if="status"
                  ></div>

                  <!-- Link to Register Page -->
                  <div class="link-account text-uppercase">
                    <p class="d-inline-block font-weight-bold mr-2">
                      Do you have already an account?
                    </p>
                    <router-link
                      :to="{ path: '/login' }"
                      class="font-weight-bold"
                      >Login here
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div v-else>
      <RegisterClosed />
    </div>
  </div>
</template>



<script>
import firebase from "firebase";
import RegisterClosed from "@/components/Auth/RegisterClosed";

// DATE PICKER
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import moment from "moment";

// PHONE - https://github.com/LouisMazel/vue-phone-number-input
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";

// COUNTRY
// https://www.michael1e.com/how-to-create-a-country-select-dropdown-in-vue/
const countries = require("i18n-iso-countries");
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

// VeeValidate
import { ValidationProvider } from "vee-validate/dist/vee-validate.full.esm";
import { extend } from "vee-validate";
// import * as rules from "vee-validate/dist/rules";
// import { messages } from "vee-validate/dist/locale/en.json";
extend("date", {
  validate: (value) =>
    /^(19|20)\d\d[/]([1-9]|0[1-9]|1[012])[/]([1-9]|0[1-9]|[12][0-9]|3[01])$/.test(
      value
    ),
  message: "Date must be in YYYY/MM/DD format",
});

export default {
  name: "Register",
  components: {
    // DatePicker,
    RegisterClosed,
    VuePhoneNumberInput,
    ValidationProvider,
  },
  data() {
    return {
      // users_can_register: true,
      auth: {
        email: "",
        password: "",
      },
      user: {
        email: "",
        name: "",
        surname: "",
        dateOfBirth: null,
        gender: "",
        phone: "",
        nationality: "",
        workingPosition: "",
        address: "",
        zipCode: "",
        city: "",
        country: "",
        representative: false,
        groupName: "",
        groupMembers: "",
      },
      status: "",
      // countries: [],
      submitStatus: 1,
    };
  },
  computed: {
    users_can_register() {
      return this.$store.state.options.roles_active.user_can_register;
    },
    countries() {
      const list = countries.getNames("en", { select: "official" });
      return Object.keys(list).map((key) => ({ value: key, label: list[key] }));
    },
    // momentDateOfBirth: {
    //   get: function () {
    //     return this.user.dateOfBirth;
    //     // return this.user.dateOfBirth.moment().format("MMMM, Do YYYY");
    //   },
    //   set: function (value) {
    //     this.user.dateOfBirth = moment(value).format("YYYY-MM-DD");
    //   },
    // },
  },
  created: function () {},
  methods: {
    doRegister() {
      const self = this;
      self.submitStatus = 0;
      self.status = "Creating the account...";

      // Create the account
      const email = this.auth.email;
      const password = this.auth.password;

      // Date Create
      let dateCreate = new Date().toLocaleString();

      // Create user object
      const user = {
        email: this.auth.email,
        name: this.user.name,
        surname: this.user.surname,
        dateOfBirth: this.user.dateOfBirth,
        gender: this.user.gender,
        phone: this.user.phone,
        nationality: this.user.nationality,
        workingPosition: this.user.workingPosition,
        address: this.user.address,
        zipCode: this.user.zipCode,
        city: this.user.city,
        country: this.user.country,
        representative: this.user.representative,
        groupName: this.user.groupName,
        groupMembers: this.user.groupMembers,
        role: "user",
        dateCreate: dateCreate,
        dateUpdate: dateCreate,
        dateUpload: null,
        partecipation: [],
      };

      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then((data) => {
          self.status = "Account created. Creating the user record...";
          user["uID"] = data.user.uid;
          this.createUser(user);
        })
        .catch(function (error) {
          self.submitStatus = 1;
          self.status = error;
        });
    },
    createUser(user) {
      const self = this;

      firebase
        .firestore()
        .collection("users")
        .add(user)
        .then(() => {
          self.status = "User record created. Redirecting...";
          self.$router.push("/");
        })
        .catch(function (error) {
          self.submitStatus = 1;
          self.status = error;
        });
    },
  },
};
</script>



<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped>
.vue-tel-input {
  border: 1px solid #ced4da;
  border-radius: 0px;
  height: calc(1.5em + 0.75rem + 2px);
}
.vue-tel-input:focus {
  outline: none;
}
.vue-tel-input:focus-within {
  /* box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6); */
  box-shadow: none;
  border-color: #ced4da;
}
input[type="tel"] {
  border-radius: 0px;
}
.vti__dropdown.open {
  outline: none !important;
}
.vti__dropdown:focus {
  outline: none !important;
}
.open:focus {
  outline: none !important;
}
</style>
