
<template>
  <div>
    <div class="row page-title mb-5 mt-5 pb-5 pt-5">
      <div class="col-10 offset-1">
        <h1 class="">Thank You!</h1>
      </div>
    </div>
    <div class="page-content row">
      <div class="col-12 col-lg-7 offset-lg-4">
        <p>
          <b>Posterheroes</b> for this year has ended.
          <br />See you the next edition!
        </p>
        <p>
          (If you see this page before the contest deadline, please contact posterheroes.org.)
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ThankYou"
};
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped>
</style>
