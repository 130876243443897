
<template>
  <div id="selector">
    <div v-if="selector_is_active" class="row mb-5">
      <div class="col-10 offset-1">
        <!-- Header -->
        <div class="page-title row my-5 pt-5">
          <div class="col-12">
            <h1 class="">Seleziona</h1>
          </div>
        </div>

        <div v-if="!submitted">
          <!-- Filters -->
          <div class="filters row mb-5">
            <div class="col-12">
              <div
                class="d-flex justify-content-between border-bottom-black pb-2"
              >
                <div class="">
                  <span class="text-uppercase mr-2">Visualizza</span>
                  <a
                    class="text-uppercase mb-0"
                    :class="{ active: status === 'all' }"
                    @click="(status = 'all'), (pagination.page = 0)"
                    >All</a
                  >
                  |
                  <a
                    class="text-uppercase mb-0"
                    :class="{ active: status === 'undefined' }"
                    @click="(status = 'undefined'), (pagination.page = 0)"
                    >Undefined</a
                  >
                  |
                  <!-- <a
                    class="text-uppercase mb-0"
                    :class="{ active: status === 'discarded' }"
                    @click="(status = 'discarded'), (pagination.page = 0)"
                    >Discarded</a
                  >
                  | -->
                  <a
                    class="text-uppercase mb-0"
                    :class="{ active: status === 'finalist' }"
                    @click="(status = 'finalist'), (pagination.page = 0)"
                    >Finalists</a
                  >
                </div>

                <div class="">
                  <p class="text-uppercase mb-0">
                    N° posters:
                    <b :class="{ 'd-none': status === 'all' }">{{
                      medias.length
                    }}</b>
                    <span :class="{ 'd-none': status === 'all' }">/</span>
                    <b>{{ posters.length }}</b>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="row mb-5">
            <div class="col-12">
              <div
                class="d-flex justify-content-between border-bottom-black pb-2"
              >
                <div class="">
                  <p class="text-uppercase mb-0">
                    Il numero di poster finalisti deve essere:
                    {{ editions.current_edition.num_finalists }}
                  </p>
                </div>
                <div class="">
                  <p class="text-uppercase mb-0">
                    Poster selezionati: {{ numFinalists }}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <!-- Medias -->
          <div class="row mb-5">
            <div class="col-12 col-lg-8 offset-lg-2">
              <div class="row">
                <!-- <div
              class="col-12 col-md-6 col-lg-4"
              :class="{ 'col-xl-3' : media.category == 'poster'}"
              v-for="(media,index) in paginatedData"
              :key="index"
            > -->
                <div
                  class="col-12 col-md-6 col-lg-4"
                  v-for="(media, index) in paginatedData"
                  :key="index"
                >
                  <!-- <pre> {{ media }} </pre> -->
                  <!-- <pre>{{ media.url }}</pre> -->
                  <!-- <pre>{{ media.link }}</pre> -->

                  <div v-if="!media.url" class="d-flex justify-content-center">
                    <img class="p-5" src="../../assets/ajax-loader.gif" />
                  </div>
                  <div v-else class="mb-3">
                    <media :media="media" />

                    <div class="select">
                      <!-- <a
                        class="p-3"
                        :class="{ active: media.status == 'discarded' }"
                        @click="
                          toggleActive(
                            'discarded',
                            category,
                            index,
                            media.number,
                            media.status == 'discarded'
                          )
                        "
                        >Scartato</a
                      > -->
                    </div>
                    <div class="select">
                      <a
                        class="p-3"
                        :class="{ active: media.status == 'finalist' }"
                        @click="
                          toggleActive(
                            'finalist',
                            category,
                            index,
                            media.number,
                            media.status == 'finalist'
                          )
                        "
                        >Selezionato</a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Pagination -->
          <div class="row mb-5">
            <!-- PREV -->
            <div class="col text-left">
              <button
                class="btn"
                :class="pagination.page === 0 ? 'd-none' : 'd-inline'"
                :disabled="pagination.page === 0"
                @click="prevPage"
              >
                &laquo;
              </button>
            </div>

            <div
              v-if="Number.isInteger(medias.length / pagination.postsPerPage)"
              class="col text-center"
            >
              <span class="text-uppercase"> Pagina </span>
              {{ pagination.page + 1 }} /
              {{
                Math.trunc(medias.length / pagination.postsPerPage) | toFixed
              }}
            </div>
            <div v-else class="col text-center">
              <span class="text-uppercase"> Pagina </span>
              {{ pagination.page + 1 }} /
              {{
                (Math.trunc(medias.length / pagination.postsPerPage) + 1)
                  | toFixed
              }}
            </div>

            <!-- NEXT -->
            <div class="col text-right">
              <button
                class="btn"
                :class="
                  pagination.page >= pageCount - 1 ? 'd-none' : 'd-inline'
                "
                :disabled="pagination.page >= pageCount - 1"
                @click="nextPage"
              >
                &raquo;
              </button>
            </div>
          </div>

          <!-- Select page -->
          <div class="row mb-5">
            <div class="col">
              <label class="text-uppercase mr-3" for="pages"
                >Select page:</label
              >
              <input
                class="border-black"
                type="number"
                id="pages"
                name="pages"
                value=""
                v-model="inputPaginationNumber"
                min="1"
                :max="pageCount"
              />
              <button class="btn p-0 m-0" @click="changePage()">
                <p class="px-2 mx-2 mb-2">></p>
              </button>
            </div>
          </div>

          <!-- Submit -->
          <div class="submit row flex-row-reverse border-bottom-black">
            <div class="col mb-3 text-right">
              <input
                type="submit"
                class="btn"
                @click="save()"
                :class="submitStatus == 1 ? 'btn-secondary' : 'btn-primary'"
                :value="submitStatus == 1 ? 'Saving' : 'Save & Continue'"
                :disabled="submitStatus == 1 ? true : false"
              />
              <br />
              <small>Potrai riprendere a selezionare successivamente</small>
            </div>
            <div class="col-1"></div>
            <div class="col mb-3 text-left">
              <input
                type="submit"
                class="btn"
                @click="save('submit')"
                :class="submitStatus == 2 ? 'btn-secondary' : 'btn-primary'"
                :value="
                  submitStatus == 2
                    ? 'Saving & Submitting'
                    : 'Save &amp; Submit'
                "
                :disabled="submitStatus == 2 ? true : false"
              />
              <br />
              <small>Non potrai più modificare la tua selezione</small>
            </div>
          </div>
        </div>

        <div v-else>
          <div class="row">
            <div class="col-12 py-3 mb-5">
              <h2 class="text-uppercase">
                Your selection has already been submitted
              </h2>
            </div>
          </div>
        </div>

        <!-- Logout -->
        <div class="row border-top pt-3">
          <div class="col-12">
            <Logout />
          </div>
        </div>
      </div>
      <!-- Col-10 offset-1 -->
    </div>
    <div v-else>
      <ThankYou />
    </div>
  </div>
</template>

<script>
import store from "@/store";
import firebase from "firebase";
import Media from "@/components/Inc/Media";
import Logout from "@/components/Auth/Logout";
import ThankYou from "@/components/ThankYou";

export default {
  name: "Selector",
  components: {
    Media,
    Logout,
    ThankYou,
  },
  data() {
    return {
      posters: [],
      medias: [],
      slice: [],
      record_id: "",
      category: "poster",
      status: "all",
      pagination: {
        page: 0,
        postsPerPage: 15,
      },
      inputPaginationNumber: 1,
      discardeds: [],
      finalists: [],
      numFinalists: 0,
      submitted: false,
      submitStatus: 0,
    };
  },
  filters: {
    toFixed: function (value) {
      if (value < 1) {
        return 1;
      }
      return value.toFixed(0);
    },
  },
  computed: {
    selector_is_active() {
      return this.$store.state.options.roles_active.selector; // selector is active?
    },
    id_current_edition() {
      return this.$store.getters.getIDCurrentEdition; // get id current edition from store
    },
    editions() {
      return this.$store.state.options.contest_editions; // get editions from store
    },
    // medias: () => {
    //   return this.medias.filter(function (media) {
    //     return media.finalist === true
    //   })
    // },
    // selected: function() {
    //   let i = 0;
    //   if (this.category === "poster") {
    //     this.posters.forEach(poster => {
    //       if (poster.finalist) {
    //         i++;
    //       }
    //     });
    //   }
    //   return i;
    // },
    pageCount: function () {
      const l = this.medias.length;
      const s = this.pagination.postsPerPage;
      return Math.ceil(l / s);
    },
    paginatedData: function () {
      const self = this;
      // Category first
      // self.medias = self.posters;

      let temp = [];
      if (self.status == "undefined") {
        self.posters.forEach((poster) => {
          if (poster.status == "undefined") {
            temp.push(poster);
          }
        });
        self.medias = temp;
      } else if (self.status == "discarded") {
        self.posters.forEach((poster) => {
          if (poster.status == "discarded") {
            temp.push(poster);
          }
        });
        self.medias = temp;
      } else if (self.status == "finalist") {
        self.posters.forEach((poster) => {
          if (poster.status == "finalist") {
            temp.push(poster);
          }
        });
        self.medias = temp;
      } else {
        // self.medias = self.posters;
        self.posters.forEach((poster) => {
          if (poster.path != null) {
            temp.push(poster);
          }
        });
        self.medias = temp;
      }

      // // console.log('MEDIA: ',self.medias);

      // Then paginate
      const start = this.pagination.page * this.pagination.postsPerPage;
      const end = start + this.pagination.postsPerPage;
      return this.medias.slice(start, end);
    },
  },

  beforeUpdate() {
    this.paginatedData.forEach((media, index) => {
      this.getDownloadUrl(index).then((url) => {
        this.paginatedData[index]["url"] = url;
      });
    });
  },

  created() {
    this.getPosts();
  },

  methods: {
    /*
    GET ALL RECORDS FROM SUBMISSIONS TABLE
    */
    getPosts() {
      const self = this;

      const condition =
        "editions." + self.id_current_edition + ".info.edition_ID";
      // console.log('condition: ', condition);

      // I Get FINALISTS Record First
      firebase
        .firestore()
        .collection("finalists")
        .where("edition_ID", "==", self.editions.current_edition.edition_ID)
        .get()
        .then((querySnapshot) => {
          // console.log('FINALISTS');
          // Controllo se c'è già un record
          if (!querySnapshot.empty) {
            querySnapshot.forEach((doc) => {
              self.record_id = doc.id;
              const data = doc.data();
              self.submitted = data.submitted;
              // Controllo che non sia già stato submittato
              if (!data.submitted) {
                // Ciclo i poster
                data.posters_finalists.forEach((poster) => {
                  poster["url"] = null;
                  self.finalists.push(poster.path);
                });
                data.posters_discardeds.forEach((poster) => {
                  poster["url"] = null;
                  self.discardeds.push(poster.path);
                });
              }
            });
          }
        })
        // Then I get ALL SUBMISSION setting finalists when path is in finalists's array
        .then(() => {
          // console.log('SUBMISSIONS');
          let poster_number = 0;
          firebase
            .firestore()
            .collection("submissions")
            .where(condition, "==", self.editions.current_edition.edition_ID)
            .get()
            .then((querySnapshot) => {
              querySnapshot.forEach((doc) => {
                const uid = doc.data().uID;
                // console.log('uid: ',uid);
                // Get the posters array
                doc
                  .data()
                  .editions[this.id_current_edition].posters.forEach(
                    (poster) => {
                      poster["category"] = "poster";
                      poster["uID"] = uid;
                      poster["url"] = null;
                      poster["number"] = poster_number;
                      // poster["finalist"] = false;
                      poster["status"] = "undefined";
                      if (self.finalists.includes(poster.path)) {
                        // poster["finalist"] = true;
                        poster["status"] = "finalist";
                      } else if (self.discardeds.includes(poster.path)) {
                        // poster["finalist"] = true;
                        poster["status"] = "discarded";
                      }
                      if (poster.path != null) {
                        self.posters.push(poster);
                      }
                      poster_number = poster_number + 1;
                    }
                  );
              });
            });
        })
        .then(() => {
          this.numFinalists = this.finalists.length;

          this.loading = false;
        })
        // Always Catch the errors!
        .catch((error) => {
          console.error("Error Getting Collection: ", error);
        });
    },

    /*
    SAVE
    */
    save(action) {
      const self = this;

      if (action === "submit") {
        // Verifico che il numero di poster selezionati per la giuria sia uguale al numero scelto dall'admin
        let num_posters_selected_is_correct = false;
        let num_posters_to_select = self.editions.current_edition.num_finalists;
        let num_posters_selected = 0;
        self.posters.forEach((poster) => {
          if (poster.status == "finalist") {
            num_posters_selected = num_posters_selected + 1;
          }
        });
        // console.log('Sono stati selezionati: '+num_posters_selected+' poster su '+num_posters_to_select);

        if (num_posters_selected == num_posters_to_select) {
          num_posters_selected_is_correct = true;
        }

        if (num_posters_selected_is_correct) {
          if (
            confirm(
              "Sei sicuro di salvare e completare? Non potrai più modificare la tua selezione"
            )
          ) {
            this.submitted = true;
            this.submitStatus = 2;
          }
        } else {
          alert(
            "Non hai selezionato il numero corretto di poster, non puoi salvare."
          );
          this.submitStatus = 0;
        }
      } else {
        this.submitStatus = 1;
      }

      if (this.submitStatus > 0) {
        self.update().then(() => {
          self.$router.go();
        });
      }
    },

    /*
    UPDATE
    */
    update() {
      return new Promise((resolve, reject) => {
        // const _posters = this.posters.filter(value => {
        //   return value.finalist === true;
        // });
        // // console.log(_posters);
        // _posters.forEach(function(v) {
        //   delete v.category;
        //   delete v.finalist;
        //   delete v.url;
        // });

        const _posters_finalists = this.posters.filter((value) => {
          return value.status === "finalist";
        });
        // console.log(_posters_finalists);
        _posters_finalists.forEach(function (v) {
          delete v.category;
          delete v.url;
          // delete v.finalist;
        });
        const _posters_discardeds = this.posters.filter((value) => {
          return value.status === "discarded";
        });
        // console.log(_posters_discardeds);
        _posters_discardeds.forEach(function (v) {
          delete v.category;
          delete v.url;
          // delete v.finalist;
        });

        // console.log('Record ID: ', this.record_id);
        if (this.record_id) {
          // console.log('True');
          // Esiste già un array contenente i poster che sono stati selezionati come finalisti
          firebase
            .firestore()
            .collection("finalists")
            .doc(this.record_id)
            .update({
              // posters: _posters,
              posters_finalists: _posters_finalists,
              posters_discardeds: _posters_discardeds,
              submitted: this.submitted,
            })
            .then((docRef) => resolve(docRef))
            .catch((error) => reject(error));
        } else {
          // console.log('False');
          // Non esiste ancora un array dei poster finalisti
          firebase
            .firestore()
            .collection("finalists")
            .add({
              // posters: _posters,
              posters_finalists: _posters_finalists,
              posters_discardeds: _posters_discardeds,
              submitted: this.submitted,
              uID_selector: this.$store.state.user.uID,
              // year: this.$store.state.options.year,
              // year: this.editions.current_edition.year,
              edition_ID: this.editions.current_edition.edition_ID,
            })
            .then((docRef) => resolve(docRef))
            .catch((error) => reject(error));
        }
      });
    },

    /*
    SAVE
    */
    toggleActive(type, category, index, number, isActive) {
      // console.log('Toggle: ',type,' - ',category,' - ',index,' - ',number,' - ',isActive);
      const self = this;

      index += this.pagination.page * this.pagination.postsPerPage;

      let num_posters_selected = 0;

      self.posters.forEach((poster) => {
        //Cambio lo status del poster
        if (poster.number == number) {
          if (isActive) {
            // Se è attivo, rimuovi lo status e decrementa il conteggio dei finalisti
            poster.status = '';
          } else {
            // Se non è attivo, imposta lo status a 'finalist'
            poster.status = type;
          }
        }
        //Conto il numero di poster finalisti
        if (poster.status == "finalist") {
          num_posters_selected = num_posters_selected + 1;
        }
      });

      // self.numFinalists = num_posters_selected + 1;
      self.numFinalists = num_posters_selected;
    },

    /*
    GET THE URL
    */
    getDownloadUrl(index) {
      return new Promise((resolve, reject) => {
        firebase
          .storage()
          .ref(this.paginatedData[index].path)
          .getDownloadURL()
          .then((url) => {
            resolve(url);
          })
          .catch(() => {
            reject("error");
          });
      });
    },

    /*
    Pagination: prev
    */
    prevPage() {
      this.pagination.page--;
    },

    /*
    Pagination: next
    */
    nextPage() {
      this.pagination.page++;
    },

    /*
    Pagination: change
    */
    changePage() {
      const self = this;
      const number_on_input = self.inputPaginationNumber;
      const number_of_pages =
        (Math.trunc(self.medias.length / self.pagination.postsPerPage) + 1) |
        self.toFixed;
      if (number_on_input > 0 && number_on_input <= number_of_pages) {
        this.pagination.page = Number(pages.value - 1);
      }
    },
  },
};
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped>
/* filters */
.filters a {
  cursor: pointer;
}
.filters a.active {
  font-weight: 700;
}

/* rates pic */
.select a {
  display: block;
  text-align: center;
  border: 1px solid #dee2e6 !important;
  color: #da265d;
  margin: 0;
}
.select a:hover {
  cursor: pointer;
  background: #dee2e6;
  color: #fff !important;
}
.select a.active {
  background: #da265d;
  border: 1px solid #da265d !important;
  color: #fff !important;
  font-weight: 700;
}
</style>
