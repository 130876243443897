<template>

  <div id="partner">
    <div v-if="partner_is_active" class="row">
      <div class="col-12">

        <!-- Header -->
        <div class="page-title row mt-5 pt-5">
          <div class="col-12">
            <h1 class>Partner</h1>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-lg-10 offset-lg-1">
            <SelectWinner />
          </div>
        </div>

        <!-- Logout -->
        <div class="row border-top pt-3 mt-5">
          <div class="col-12">
            <Logout/>
          </div>
        </div>

      </div>

    </div>
    <div v-else>
      <ThankYou />
    </div>
  </div>
</template>

<script>
import firebase from "firebase";
import SelectWinner from "@/components/Content/Partner/SelectWinner";
import Logout from "@/components/Auth/Logout";
import ThankYou from "@/components/ThankYou";

export default {
  name: "Partner",
  components: {
    SelectWinner,
    Logout,
    ThankYou
  },
  data() {
    return {
    };
  },
  computed: {
    partner_is_active() {
      return this.$store.state.options.roles_active.partner; // partner is active?
    },
  },
};
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped>

</style>
