<template>
  <div>
    <a class="logout text-uppercase" @click.prevent="doLogout()">Logout</a>
  </div>
</template>

<script>
import firebase from "firebase";
import store from "@/store";

export default {
  name: "Logout",
  methods: {
    doLogout() {
      const self = this;
      firebase
        .auth()
        .signOut()
        .then(() => {
          self.resetUser();
          self.resetPosters();
          // self.$session.destroy();
          // cache.delete(request, {options}).then(function(found) {
          //   // your cache entry has been deleted if found
          // });
          self.$router.push("/login");
        })
        .catch(function (error) {
          // console.log("error: ", error);
        });
    },
    resetUser() {
      store.commit("resetUser");
    },
    resetPosters() {
      store.commit("resetPosters");
    },
  },
};
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped>
</style>
