
<template>
  <div>
    <!-- <div v-if="contest_is_active != null"> -->
    <!-- <div v-if="contest_is_active"> -->

    <!-- Switch ruoli == Admin -->
    <div v-if="role === 'admin'">
      <Admin />
    </div>
    <!-- Switch ruoli == User -->
    <div v-if="role === 'user'">
      <User />
    </div>
    <!-- Switch ruoli == Selector -->
    <div v-if="role === 'selector'">
      <Selector />
    </div>
    <!-- Switch ruoli == SelectorAI -->
    <div v-if="role === 'selectorAI'">
      <!-- <SelectorAI /> -->
      <Selector />
    </div>
    <!-- Switch ruoli == Jury -->
    <div v-else-if="role === 'jury'">
      <Jury />
    </div>
    <!-- Switch ruoli == Partner -->
    <div v-else-if="role === 'partner'">
      <Partner />
    </div>
    <!-- Switch ruoli == Analytics -->
    <div v-else-if="role === 'analytics'">
      <Analytics />
    </div>
    <!-- Switch ruoli == Default -->
    <!-- <div v-else>
          Questo ruolo non ha una sua vista
          <Forbidden/>
        </div>-->

    <!-- </div>
      <div v-else>
        <Forbidden />
      </div>
    </div> -->
    <!-- <div v-else>
      <img src="@/assets/ajax-loader.gif" />
    </div> -->
  </div>
</template>

<script>
import firebase from "firebase";
import Admin from "@/components/Content/Admin.vue";
import User from "@/components/Content/User.vue";
import Selector from "@/components/Content/Selector.vue";
// import SelectorAI from "@/components/Content/SelectorAI.vue";
import Jury from "@/components/Content/Jury.vue";
import Partner from "@/components/Content/Partner.vue";
import Analytics from "@/components/Content/Analytics.vue";
// import Forbidden from "@/components/Forbidden.vue";

export default {
  name: "Index",
  components: {
    Admin,
    User,
    Selector,
    // SelectorAI,
    Jury,
    Partner,
    Analytics,
    // Forbidden
  },
  data() {
    return {
      // active: true,
      // role: true
    };
  },
  computed: {
    // contest_is_active () {
    //   return this.$store.state.options.contest_active // contest is active?
    // },
    role() {
      return this.$store.state.user.role; // get role from store
    },
  },
  // created() {
  //   firebase.auth().onAuthStateChanged(user => {
  //     this.getUserInfo(user);
  //   });
  // },
  methods: {
    // getUserInfo(user) {
    //   // console.log("getUserInfo:", user);
    //   firebase
    //     .firestore()
    //     .collection("users")
    //     .where("uID", "==", user.uid)
    //     .get()
    //     .then(querySnapshot => {
    //       querySnapshot.forEach(doc => {
    //         // Set local values
    //         this.role = doc.data().role;
    //         // Set global values
    //         // Active for role
    //         } else {
    //           this.active = false;
    //         }
    //       });
    //     })
    //     .catch(function(error) {
    //       console.error(error);
    //     });
    // }
  },
};
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped>
</style>
