<template>
  <div id="gestionale" class="row">
    <!-- <pre
      class="border"
      style="
        color: lightblue;
        font-size: 10px;
        background: black;
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 100;
      "
    >
      {{ this.$store.state.options }}
    </pre> -->
    <!-- <pre class="border" style="color:lightblue; font-size:10px; background: black;
      position: fixed;
      bottom: 0;
      left: 0;
      z-index: 100;">
      {{this.$store.state.user}}
    </pre> -->
    <!-- <pre
      class="border"
      style="
        color: lightblue;
        font-size: 10px;
        background: black;
        position: fixed;
        bottom: 0;
        right: 0;
        z-index: 100;
        max-width: 600px;
      "
    >
      {{ this.$store.state.posters }}
    </pre> -->

    <!-- <div v-if="this.$store.state.options.contest_active !== null">
      <div v-if="this.$store.state.options.contest_active"> -->
    <div class="col-12">
      <router-view />
    </div>
    <!-- </div>
      <div v-else>
        <ThankYou/>
      </div>
    </div> -->
  </div>
</template>

<script>
import router from "@/router";
import store from "./store";
import firebase from "firebase";
// import ThankYou from "@/components/ThankYou";

export default {
  name: "App",
  components: {
    // ThankYou
  },
  data() {
    return {
      // active: this.$store.state.options.active
    };
  },
  // created() {
  beforeMount() {
    this.getOptions();
    this.getUserIsLoggedIn();
  },
  methods: {
    getOptions() {
      firebase
        .firestore()
        .collection("options")
        .doc("general")
        .get()
        .then((doc) => {
          // Set up options into store
          store.commit("setContestIsActive", doc.data().contest_active);
          store.commit("setRolesActive", doc.data().roles_active);
          store.commit("setContestEditions", doc.data().contest_editions);
        });
    },
    getUserInfo(user) {
      firebase
        .firestore()
        .collection("users")
        .where("uID", "==", user.uid)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            // Set local values
            // this.role = doc.data().role;
            // store.commit('setUserRole', doc.data().role)

            // Set global values
            // console.log(doc.data());
            store.commit("setUser", doc.data());
          });
        })
        .catch(function (error) {
          console.error(error);
        });
    },
    getUserIsLoggedIn() {
      let self = this;
      firebase.auth().onAuthStateChanged(function (user) {
        if (user) {
          // User is signed in.
          store.commit("setUserID", user.uid);
          self.getUserInfo(user);
          // self.$router.push("/");
          self.$router.push("/").catch(() => {});
        } else {
          // No user is signed in.
          // self.$router.push('/login');
          router.push({ name: "loginPage" }).catch((err) => {
            if (err.name !== "NavigationDuplicated") {
              console.log("Error: ", err);
            }
          });
        }
      });
    },
  },
};
</script>
