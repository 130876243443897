<template>
  <div class="register">
    <Register />
  </div>
</template>

<script>
// @ is an alias to /src
import Register from '@/components/Auth/Register.vue'

export default {
  name: 'RegisterPage',
  components: {
    Register
  }
}
</script>
