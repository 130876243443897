<template>
  <div id="admin">
    <div class="">
      <div class="row mb-5 mt-5 pb-5 pt-5">
        <div class="col-10 offset-1">
          <div class="page-title">
            <h1 class="">Admin</h1>
          </div>
          <p>
            <Logout />
          </p>
        </div>
      </div>

      <div class="filters row border-bottom mb-5">
        <div class="col-auto text-left">
          <a
            :class="{ active: menu === 'winners' }"
            v-on:click="menu = 'winners'"
            >Winners</a
          >
          |
          <a
            :class="{ active: menu === 'searchUser' }"
            v-on:click="menu = 'searchUser'"
            >Search User</a
          >
          |
          <a
            :class="{ active: menu === 'createUser' }"
            v-on:click="menu = 'createUser'"
            >Create User</a
          >
          |
          <a
            :class="{ active: menu === 'settings' }"
            v-on:click="menu = 'settings'"
            >Settings</a
          >
        </div>
      </div>

      <div class="col-12">
        <components v-bind:is="component" />
      </div>

      <!-- <div class="col-12 border mb-5">
        <Settings />
      </div>
      <div class="col-12 border mb-5">
        <Posters />
      </div>
      <div class="col-12 border mb-5">
        <CreateUser />
      </div> -->
    </div>
  </div>
</template>

<script>
import firebase from "firebase";
// import Logout from "@/components/Auth/Logout";
import Winners from "@/components/Content/Admin/Winners";
import SearchUser from "@/components/Content/Admin/SearchUser";
import CreateUser from "@/components/Content/Admin/CreateUser";
import Settings from "@/components/Content/Admin/Settings";
import Logout from "@/components/Auth/Logout";

export default {
  name: "Admin",
  components: {
    Winners,
    SearchUser,
    CreateUser,
    Settings,
    Logout,
  },
  data() {
    return {
      menu: "winners",
      component: "Winners",
      // switch1: true,
      // empty: false,
      // submitStatus: 1,
    };
  },
  computed: {
    // setProperties: function() {
    //   if (this.component === 'Analyticsusers') {
    //     return { users: this.users, nationality: this.nationality }
    //   }
    //   if (this.component === 'Analyticssubmissions') {
    //     return { submissions: this.submissions }
    //   }
    //   if (this.component === 'Analyticsratings') {
    //     return { ratesPosters: this.ratesPosters, jury: this.jury }
    //   }
    // }
  },
  watch: {
    menu() {
      this.component = this.menu;
    },
  },
  created() {},
  methods: {},
};
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped>
</style>
