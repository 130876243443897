<template>
  <div id="create-user">
    <!-- <div v-if="users_can_register"> -->
    <!-- <div class="d-none"> -->

    <div>
      <div class="page-title row mb-5 mt-5">
        <div class="col-10 offset-1">
          <h2 class="font-weight-bold">Create user</h2>
          <!-- <p class="strong">Don't forget to fill all the fields!</p> -->
        </div>
      </div>

      <form class="" @submit.prevent="doRegister">
        <!-- Login info -->
        <div class="form-group row mb-5">
          <aside class="col-12 col-lg-4 col-xl-3 offset-xl-1">
            <h2>Login info</h2>
            <p class="small">These will be your login username and password</p>
          </aside>
          <div class="col-12 col-lg-8 offset-lg-4 col-xl-7 offset-xl-0">
            <div class="mb-3">
              <label>E-mail</label>
              <input
                class="form-control"
                type="email"
                name="email"
                v-model="auth.email"
                required
              />
            </div>
            <div class="mb-3">
              <label>Password</label>
              <input
                class="form-control"
                type="password"
                name="password"
                v-model="auth.password"
                required
                autocomplete="on"
              />
            </div>
          </div>
        </div>

        <!-- Personal Info -->
        <div class="form-group row">
          <aside class="col-12 col-lg-4 col-xl-3 offset-xl-1">
            <h2>Personal info</h2>
            <p class="small">* Required fields</p>
          </aside>

          <div class="col-12 col-lg-8 offset-lg-4 col-xl-7 offset-xl-0">
            <div class="mb-3">
              <label>* Name</label>
              <input
                class="form-control"
                type="text"
                name="name"
                v-model="user.name"
                required
              />
            </div>
            <div class="mb-3">
              <label>* Surname</label>
              <input
                class="form-control"
                type="text"
                name="surname"
                v-model="user.surname"
                required
              />
            </div>
            <div class="mb-3">
              <label class="d-block">* Role</label>
              <select v-model="user.role" required>
                <option
                  v-for="role in roles"
                  v-bind:key="role.value"
                  v-bind:value="role.value"
                >
                  {{ role.text }}
                </option>
              </select>
            </div>
            <div class="mb-3">
              <label>Phone</label>
              <!-- <template class="d-block"> -->
              <vue-tel-input v-model="user.phone" validCharactersOnly></vue-tel-input>
              <!-- </template> -->
            </div>
            <div class="mb-3">
              <label>Link: Website, Social, ...</label>
              <input
                class="form-control"
                type="text"
                name="link"
                v-model="user.link"
              />
            </div>
          </div>
        </div>

        <!-- Submit + Status -->
        <div class="form-group row">
          <aside class="col-12 col-lg-4 col-xl-3 offset-xl-1"></aside>

          <!-- Submit -->
          <div class="col-12 col-lg-8 offset-lg-4 col-xl-7 offset-xl-0">
            <div class="mb-5">
              <input
                type="submit"
                class="btn"
                :class="submitStatus == 1 ? 'btn-primary' : 'btn-secondary'"
                :value="submitStatus == 1 ? 'Register user' : 'Registering'"
                :disabled="submitStatus == 1 ? false : true"
              />
            </div>
            <!-- Status -->
            <div
              class="mb-5 bg-secondary text-white p-3 border mb-3 rounded"
              v-html="status"
              v-if="status"
            ></div>
          </div>
        </div>
      </form>
    </div>
    <!-- </div> -->
    <!-- </div> -->

    <!-- <div v-else> -->
    <!-- <div>
      <thankyou />
    </div> -->
  </div>
</template>

<script>
import firebase from "firebase";
// import thankyou from "@/components/ThankYou";

// https://www.npmjs.com/package/vue-tel-input
import { VueTelInput } from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";

export default {
  name: "CreateUser",
  components: {
    VueTelInput,
    // thankyou
  },
  data() {
    return {
      auth: {
        email: "",
        password: "",
      },
      user: {
        name: "",
        surname: "",
        role: "",
        phone: "",
        link: ""
      },
      roles: [
        { text: "Selector", value: "selector" },
        { text: "Jury", value: "jury" },
        { text: "Analytics", value: "analytics" },
        { text: "Partner", value: "partner" },
      ],
      status: "",
      submitStatus: 1,
      phoneProps: {
        mode: "international",
        defaultCountry: "IT",
        disabledFetchingCountry: false,
        disabled: false,
        disabledFormatting: false,
        placeholder: "Enter a phone number",
        required: false,
        enabledCountryCode: false,
        enabledFlags: true,
        preferredCountries: [],
        onlyCountries: [],
        ignoredCountries: [],
        autocomplete: "off",
        name: "telephone",
        maxLen: 20,
        wrapperClasses: "",
        inputClasses: "form-control",
        dropdownOptions: {
          disabledDialCode: false,
        },
      },
    };
  },
  computed: {},
  methods: {
    doRegister() {
      const self = this;
      self.submitStatus = 0;
      self.status = "Creating the account...";

      // Create the account
      const email = this.auth.email;
      const password = this.auth.password;

      // Create user object
      const user = {
        name: this.user.name,
        surname: this.user.surname,
        role: this.user.role,
        phone: this.user.phone,
        link: this.user.link,
      };

      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then((data) => {
          self.status = "Account created. Creating the user record...";
          user["uID"] = data.user.uid;
          this.createUser(user);
        })
        .catch(function (error) {
          self.submitStatus = 1;
          self.status = error;
        });
    },
    createUser(user) {
      const self = this;

      firebase
        .firestore()
        .collection("users")
        .add(user)
        .then(() => {
          self.status = "User record created.";
          // self.$router.push("/");
        })
        .catch(function (error) {
          self.submitStatus = 1;
          self.status = error;
        });
    },
  },
};
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped>
.vue-tel-input {
  border: 1px solid #ced4da;
  border-radius: 0px;
  height: calc(1.5em + 0.75rem + 2px);
}
.vue-tel-input:focus {
  outline: none;
}
.vue-tel-input:focus-within {
  /* box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6); */
  box-shadow: none;
  border-color: #ced4da;
}
input[type="tel"] {
  border-radius: 0px;
}
.vti__dropdown.open {
  outline: none !important;
}
.vti__dropdown:focus {
  outline: none !important;
}
.open:focus {
  outline: none !important;
}
</style>
