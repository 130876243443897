<template>
  <div id="login">
    <div class="row">
      <div class="col-12 col-lg-6 pl-0">
        <div class="position-sticky" style="top: 0">
          <img
            class="w-100 vh-100 object-fit-cover"
            src="@/assets/PH-login.jpg"
          />
        </div>
      </div>
      <div class="col-12 col-lg-6">
        <form class="row h-100" @submit.prevent="doLogin">
          <div
            class="col-12 col-lg-8 offset-lg-1 d-flex flex-column justify-content-center"
          >
            <!-- Inputs -->
            <div class="row mb-5">
              <div class="col-12">
                <div class="">
                  <!-- <label>Your E-mail</label> -->
                  <input
                    class="mb-4"
                    type="text"
                    name="email"
                    placeholder="Email"
                    v-model="email"
                  />
                </div>

                <div class="">
                  <!-- <label>Your Password</label> -->
                  <input
                    class="mb-4"
                    type="password"
                    name="password"
                    placeholder="Password"
                    v-model="password"
                    required
                    autocomplete="on"
                  />
                  <a
                    class="text-link text-uppercase font-weight-bold"
                    href="#"
                    @click="sendPasswordResetEmail()"
                    >Forgot your password?</a
                  >
                </div>
              </div>
            </div>

            <!-- Submit + Status-->
            <div class="row">

              <!-- Submit -->
              <div class="col-12">
                <div class="mb-3">
                  <input
                    type="submit"
                    class="btn"
                    :class="submitStatus == 1 ? 'btn-primary' : 'btn-secondary'"
                    :value="submitStatus == 1 ? 'Login' : 'Logging in'"
                    :disabled="submitStatus == 1 ? false : true"
                  />
                </div>
                <!-- Link to Register Page -->
                <div class="link-account text-uppercase">
                  <p class="d-inline-block font-weight-bold mr-2">
                    New to Posterheroes?
                  </p>
                  <router-link
                    :to="{ path: '/register' }"
                    class="font-weight-bold"
                    >Register
                  </router-link>
                </div>

                <!-- Status -->
                <div
                  class="mb-5 bg-secondary text-white p-3 border mb-3 rounded"
                  v-html="status"
                  v-if="status"
                ></div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase";

export default {
  name: "Login",
  data() {
    return {
      email: "",
      password: "",
      uid: "",
      status: "",
      submitStatus: 1,
    };
  },
  created() {
    if (firebase.auth().currentUser) {
      if (
        this.$store.state.user.uid == null ||
        this.$store.state.user.role == null
      ) {
        this.$router.push("/login");
      } else {
        this.$router.push("/");
      }
    }
  },
  methods: {
    sendPasswordResetEmail() {
      const self = this;
      const email = self.email;

      firebase
        .auth()
        .sendPasswordResetEmail(email)
        .then(function (response) {
          self.status = "Check your email for reset password instructions. If you don't receive the email, check your SPAM.";
        })
        .catch(function (error) {
          self.status = error;
        });
    },
    doLogin() {
      const self = this;
      const email = self.email;
      const password = self.password;

      self.submitStatus = 0;

      self.status = "Loggin in...";
      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then((docRef) => {
          this.uid = docRef.user.uid;
          self.status = "Logged in. Redirect...";
        })
        .then(() => {
          firebase
            .firestore()
            .collection("users")
            .where("uID", "==", self.uid)
            .get()
            .then((querySnapshot) => {
              querySnapshot.forEach((doc) => {
                this.$store.state.user = doc.data();
              });
            })
            .catch(function (error) {
              // console.log(error);
              self.submitStatus = 1;
            });
        })
        .then(() => {
          self.$router.push("/");
        })
        .catch(function (error) {
          self.submitStatus = 1;
          self.status = error;
        });
    },
  },
};
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped>
</style>
